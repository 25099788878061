import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RootLayout } from '../_components';
import { Redirection } from '../_components/Redirection';
import { Login } from '../Auth';
import { CandidateAdd } from '../Candidate/CandidateAdd';
import { CandidateList } from '../Candidate/CandidateList';
import { CandidateSingle } from '../Candidate/CandidateSingle';
import { ClientUserAdd, ClientUserList } from '../Client';
import { CompanyAdd, CompanyList } from '../Company';
import Home from '../Home/Home';
import { useUser } from '../Hooks/useUser';
import { JobRoleAdd } from '../JobRole/JobRoleAdd';
import { JobRoleList } from '../JobRole/JobRoleList';
import { NotificationList } from '../Notification/NotificationList';
import { OrderAdd } from '../Order/OrderAdd';
import { OrderList } from '../Order/OrderList';
import { OrderSingle } from '../Order/OrderSingle';
import Profile from '../Profile/Profile';
import { UserAdd } from '../User/UserAdd';
import { UserList } from '../User/UserList';
import { UserContext } from '../Utils/Contexts';
import { OrderEdit } from '../Order/OrderEdit';

export default function Router() {
  const user = useUser();
  return (
    <>
      <UserContext.Provider value={user}>
        <Routes>
          <Route path='/' element={<RootLayout />}>
            <Route index element={<Home />} />
            <Route path='profile' element={<Profile />} />
            <Route path='dashboard' element={<Home />} />
            <Route path='users' element={<UserList />} />
            <Route path='usersadd/:id' element={<UserAdd />} />
            <Route path='clients' element={<ClientUserList />} />
            <Route path='clientsadd/:id' element={<ClientUserAdd />} />
            <Route path='companies' element={<CompanyList />} />
            <Route path='companiesadd/:id' element={<CompanyAdd />} />
            <Route path='jobroles' element={<JobRoleList />} />
            <Route path='jobrolesadd/:id' element={<JobRoleAdd />} />
            <Route path='orders' element={<OrderList />} />
            <Route path='orderadd/:id' element={<OrderAdd />} />
            <Route path='orderedit/:id' element={<OrderEdit />} />
            <Route path='ordersingle/:id/:order_id' element={<OrderSingle />} />
            <Route path='addcandidate/:id/:orderdetails_id' element={<CandidateAdd />} />
            <Route path='candidates' element={<CandidateList />} />
            <Route path='candidatesingle/:id' element={<CandidateSingle />} />
            <Route path='notifications' element={<NotificationList />} />
          </Route>

          <Route path='/login' element={<Login />} />
          <Route path='/redirect' element={<Redirection />} />
        </Routes>
      </UserContext.Provider>
    </>
  );
}
