import { authHeader, authHeaderMulti, handleResponse } from '../_helpers';

import moment from 'moment';
import { toast } from 'react-toastify';
import { apiConstants } from '../_constants';
import { toastConstant } from '../_constants/toast.constants';

export const miscService = {
  fileUpload,
  createSignedUploadUrl,
  b64toBlob,
  formatDate,
  formatTime,
  getParameterByName,
};

function createSignedUploadUrl(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/get_signed_upload_url', requestOptions).then(handleResponse);
}


function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function fileUpload(file, name) {
  console.log(file, name);
  let data = new FormData();
  data.append('file', file, name);
  data.append('name', 'filename');

  const requestOptions = {
    method: 'POST',
    body: data,
    headers: authHeaderMulti(),
  };
  return fetch(apiConstants.apiUrl + '/fileupload/local', requestOptions).then(handleResponse);
}



function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

function formatTime(date) {
  let d = new Date(date),
    hours = d.getHours(),
    minutes = '' + d.getMinutes(),
    a = ' AM';

  if (hours > 12) {
    hours = '' + hours - 12;
    a = ' PM';
  } else if (hours === 0) {
    hours = '12';
  } else {
    hours = '' + hours;
  }

  if (minutes.length < 2) minutes = '0' + minutes;

  return hours + ':' + minutes + a;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


// Return user name abbreviation
export const userAbb = (name) => {
  if (!name) {
    return ''
  }
  let abbName =
    name.trim()?.toString()?.split(' ').length > 1
      ? `${name.toString().split(' ')[0].charAt(0).toUpperCase()}${name
        .toString()
        ?.split(' ')[1]
        .charAt(0)
        .toUpperCase()}`
      : `${name.toString().slice(0, 2).toUpperCase()}`;

  return abbName;
};

export async function validateFileSize(fileSize) {
  const fileSizeInMB = fileSize / 1024 / 1024;
  console.log("filesize----", fileSize, "fileSizeInMB", fileSizeInMB);
  if (fileSizeInMB > 2) {
    return false;
  }
  return true;
}

export const UploadS3UsingFetch = async (file, signedurl) => {
  try {
    const response = await fetch(signedurl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'inline',
      },
      body: file,
    });

    if (!response.ok) {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log('Upload Error:', error);
    return false;
  }
};

export const manipulateOrderId = (value, padding, orderindex) => { // Added parameters
  var zeroes = new Array(padding + 1).join("0");
  return orderindex + '/' + (zeroes + value).slice(-padding);
}



export const copyTextToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Link Copied!', toastConstant.TOAST_PAYLOAD)

    } catch (err) {
      toast.error('Unable to Copy!', toastConstant.TOAST_PAYLOAD)
    }
  } else {
    toast.error('Unable to Copy!', toastConstant.TOAST_PAYLOAD)
  }
};


export const openMailClient = (to, subject, body) => {
  const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoLink;
};

export const sendMail = (to, body) => {

  const url = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${to}&su=candidate update&body=${body}&ui=2&tf=1&pli=1`;

  window.open(url, 'sharer', 'toolbar=0,status=0,width=648,height=395');
};


export function getFileType(fileType) {
  // Check if the file type starts with 'image/' for images
  if (fileType.startsWith('image/')) {
    return 'image';
  }

  // Check for document types (you can add more types as needed)
  const documentTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/plain'];
  if (documentTypes.includes(fileType)) {
    return 'document';
  }

  return 'unknown'; // If the file type doesn't match any known types
}

// ... existing code ...
export function timeAgo(date) {
  const now = moment();
  const pastDate = moment(date);
  const daysDiff = now.diff(pastDate, 'days');

  if (daysDiff > 2) {
    return pastDate.format('L'); // Show date if more than 2 days
  } else {
    return pastDate.from(now); // Use moment's from method for relative time
  }
}
// ... existing code ...