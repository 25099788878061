import { Copy, Mail, Trash, UserRoundPlus } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from '../_components/Loader';
import WarningModal from '../_components/WarningModal';
import { ROLE } from '../_constants/role.constants';
import { STATUS_CONSTANT } from '../_constants/status.constants';
import { toastConstant } from '../_constants/toast.constants';
import { candidateService } from '../_services/candidate.service';
import { sendMail, userAbb } from '../_services/misc.service';
import { DownloadCv } from '../Candidate/DonwloadCv';
import ExportToCsv from '../Candidate/ExportCandidate';
import { useUser } from '../Hooks/useUser';

export const AppliedCandidateList = ({ orderData, clientData }) => {
  const user = useUser();
  const navigate = useNavigate();
  const tabs = [
    { label: 'All', status: -1 },
    { label: 'New', status: 1 },
    { label: 'Interview', status: 4 },
    { label: 'Please Mobilize', status: 5 },
    { label: 'Rejected', status: 3 },
    { label: 'Mobilized', status: 6 },
    { label: 'Waiting List', status: 7 },
  ];

  const [selectedTab, setSelectedTab] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [candidateList, setCandidateList] = useState([]);
  const [shareUrl, setShareUrl] = useState(null);
  const [cvData, setCvData] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    order_id: orderData?.order_id,
    orderdetails_id: orderData?.orderDetails_id,
  });

  useEffect(() => {
    if (orderData.orderDetails_id && orderData?.order_id) getCandidateList(1);
  }, [orderData]);

  useEffect(() => {
    if (clientData && orderData.orderDetails_id && orderData?.order_id) {
      if (user?.role !== ROLE.CLIENT_USER) {
        setShareUrl(
          window.location.origin +
            '/redirect?token=' +
            clientData?.token +
            '&id=' +
            orderData.orderDetails_id +
            '&order_id=' +
            orderData?.order_id,
        );
      }
    }
  }, [clientData, orderData]);

  const getCandidateList = async (pageNo, tab) => {
    try {
      setIsLoading(true);
      if (pageNo) {
        query.pageVo.pageNo = pageNo;
      }
      if (tab >= 0) {
        query.status = tab;
      } else {
        delete query.status;
      }
      setQuery(query);

      const response = await candidateService.CandidateList(query);

      if (response) {
        setCandidateList(response.data.rows);
        const cvUrls = response.data.rows
          .filter((data) => data.cvurlsigned) // Ensure cvurl exists to avoid undefined URLs
          .map((data) => data.cvurlsigned);
        // .map((data) => apiConstants.imgUrlS3 + data.cvurl);
        setCvData(cvUrls);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCandidate = async (data) => {
    const deleteQuery = {
      id: data?.id,
    };

    try {
      const response = await candidateService.deleteCandidate(deleteQuery);

      if (response) {
        setShowWarningModal(false);
        getCandidateList(1, selectedTab);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('this is finally ');
    }
  };

  const renderUserItem = () => {
    return candidateList.map((candidate) => {
      return (
        <tr
          key={candidate?.id}
          className='cursor-pointer'
          onClick={() => navigate('/candidatesingle/' + candidate?.id)}
        >
          <td>
            <span className='nameabb'>{userAbb(candidate?.name)}</span>
          </td>
          <td>{candidate?.name}</td>
          <td>{candidate?.job_name}</td>
          <td style={{ minWidth: '150px' }}>
            <span className='activeicon'>{STATUS_CONSTANT[candidate?.status] || 'Unknown'}</span>
          </td>
          <td>{moment(parseInt(candidate?.available)).format('MMM-DD-YYYY')}</td>
          {candidate.extra_field_value.map((extradata) =>
            Object.entries(extradata).map(([key, value]) => <td key={key}>{value}</td>),
          )}
          {user?.role !== ROLE.CLIENT_USER && (
            <td>
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  setShowWarningModal(true);
                  setSelectedData(candidate);
                }}
              >
                <Trash size={15} />
              </span>
            </td>
          )}
        </tr>
      );
    });
  };

  return (
    <div>
      <div className='tabscontainer'>
        {tabs?.map(({ label, status }) => {
          return (
            <div
              className={'tabitem cursor-pointer ' + (status == selectedTab ? 'selectedtab' : '')}
              onClick={() => {
                setSelectedTab(status);
                getCandidateList(1, status);
              }}
              key={status}
            >
              {label}
            </div>
          );
        })}
        {user?.role !== ROLE.CLIENT_USER && (
          <div className='tabitem d-flex gap-4'>
            <div
              className='mailbtn'
              onClick={async () => {
                sendMail(clientData?.email, shareUrl);
              }}
            >
              <Mail size={18} />
            </div>
            <CopyToClipboard text={shareUrl}>
              <div
                className='mailbtn'
                onClick={() => toast.success('Link Copied!', toastConstant.TOAST_PAYLOAD)}
              >
                <Copy size={15} />
              </div>
            </CopyToClipboard>
          </div>
        )}
        <ExportToCsv data={candidateList} />
        <DownloadCv cvurl={cvData} key={cvData} />
      </div>
      <div className='tableContainer'>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Job</th>
                <th>Status</th>
                <th>Availability</th>
                {orderData?.extra_field?.map(({ name, index }) => {
                  return <th key={index}>{name}</th>;
                })}
                {user?.role !== ROLE.CLIENT_USER && <th></th>}
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {candidateList.length == 0 && !isLoading && <div className='nodata'> No Data Found</div>}
          {!!isLoading && candidateList.length == 0 && <Loader />}
        </div>
        {user?.role == ROLE.USER && (
          <div
            className='addcandidate'
            onClick={() => navigate('/addcandidate/0/' + orderData?.orderDetails_id)}
          >
            <UserRoundPlus />
          </div>
        )}
      </div>
      {showWarningModal && (
        <WarningModal
          isOpen={showWarningModal}
          content={'Are you sure want to delete user?'}
          onClickYes={() => {
            handleDeleteCandidate(selectedData);
          }}
          onClose={() => {
            setShowWarningModal(false);
          }}
        />
      )}
    </div>
  );
};
