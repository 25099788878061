import { BellDot, Maximize } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from '../_components/Loader';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { timeAgo } from '../_services/misc.service';
import { notificationService } from '../_services/notification.service';
import { useUser } from '../Hooks/useUser';
import '../Notification/notification.css';

export const DashboardNotifications = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [unReadCount, setUnReadCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 5,
    },
  });

  useEffect(() => {
    getAllNotification(1);
  }, []);

  const getAllNotification = async (pageNo) => {
    try {
      setIsLoading(true);
      if (pageNo) {
        query.pageVo.pageNo = pageNo;
        setQuery(query);
      }

      const res = await notificationService.notificationList(query);

      if (res.data.rows) {
        setUnReadCount(res.data.unread_count);

        setNotificationList(res.data.rows);
      }
    } catch (err) {
      // toast.error(err.message, toastConstant.TOAST_PAYLOAD);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigation = (notification) => {
    if (notification.mode == 1) {
      navigate('/ordersingle/' + notification?.orderDetails_id + '/' + notification?.order_id);
    } else if (notification.mode == 2 || notification?.mode == 3) {
      navigate('/candidatesingle/' + notification?.candidate_id);
    } else {
      toast.warn('No Data Found', toastConstant.TOAST_PAYLOAD);
    }
    ChangeToRead(notification.id);
  };

  const ChangeToRead = async (id) => {
    try {
      const query = {
        id: id,
        is_read: 1,
      };
      if (user?.role == ROLE.ADMIN) {
        query.is_read_admin = 1;
        delete query?.is_read;
      }

      await notificationService.addNotification(query);
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    }
  };

  return (
    <div className='dashnotifcationcontainer'>
      <div className='notificationbox'>
        <div className='notificationhead d-flex gap-1 justify-content-between'>
          <div className='d-flex justify-content-center align-items-center'>
            <div className='position-relative'>
              <img src='/img/bellicon.png' className='headicon' />
              {unReadCount > 0 && <span className='unreadcount'>{unReadCount}</span>}
            </div>
            Notifications
          </div>
          <Maximize className='cursor-pointer' onClick={() => navigate('/notifications')} />
        </div>
        <div className='notificationcontent'>
          {notificationList.map((notification) => (
            <div
              className='notificationitem'
              key={notification?.id}
              onClick={() => handleNavigation(notification)}
            >
              <div className='bellicon'>
                <BellDot />
              </div>
              <div className='nt-title'>
                {notification?.mode == 1
                  ? 'Order Added'
                  : notification.mode == 2
                  ? 'Candidate Added'
                  : 'Status Changed'}
                <br />
                <span className='notificationtext'>{notification?.note}</span>
              </div>
              <span className='datetext'>{timeAgo(notification?.createdAt)}</span>
            </div>
          ))}
          {notificationList.length == 0 && !isLoading && (
            <div className='nodata'>No Notifications</div>
          )}
        </div>
        {!!isLoading && <Loader />}
      </div>
    </div>
  );
};
