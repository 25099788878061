import { SquarePen, User2Icon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { apiConstants } from '../_constants';
import { toastConstant } from '../_constants/toast.constants';
import { miscService, UploadS3UsingFetch, validateFileSize } from '../_services/misc.service';
import { userService } from '../_services/user.service';
import { useUser } from '../Hooks/useUser';
import ChangePasswordModal from './ChangePassword';
import './profile.css';

export default function Profile() {
  const localuser = useUser();

  const [userData, setUserData] = useState({});
  const [imageUploading, setImageUploading] = useState(false);
  const [showChangePassword, setChangePassword] = useState(false);

  useEffect(() => {
    if (localuser?.id) {
      getUserById(localuser?.id);
    }
  }, [localuser]);

  const getUserById = async (id) => {
    const query = {
      id: id,
    };
    try {
      const response = await userService.getUserByid(query);
      if (response?.data) {
        setUserData(response?.data);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside finally block');
    }
  };

  const handleImageUpload = async (event) => {
    setImageUploading(true);
    const file = event?.target?.files[0];
    try {
      const ext = file.name.split('.').pop();
      if (await validateFileSize(file?.size)) {
        const response = await miscService.createSignedUploadUrl({
          type: 'image',
          mime_type: file.type,
          ext,
        });

        const { signedUrl, filename } = response.data;
        const uploadResponse = await UploadS3UsingFetch(file, signedUrl);
        if (uploadResponse) {
          const profileUpdateQuery = {
            id: userData?.id,
            profile_image: filename,
          };
          const data = await userService.addUser(profileUpdateQuery);
          if (data) {
            setUserData({ ...userData, profile_image: filename });
          } else {
            toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
          }
        } else {
          toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
        }
      } else {
        toast.error('Image size is larger!!', toastConstant.TOAST_PAYLOAD);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setImageUploading(false);
    }
  };
  return (
    <div>
      <div className=' userlistcard' style={{ color: '#858796' }}>
        <div className='card-header'>
          <div className='d-sm-flex align-items-center justify-content-between'>
            <div className='userlisthead'>
              <User2Icon size={20} />
              Profile
            </div>
          </div>
        </div>
        <div className='card-body pb-5'>
          <div className='row'>
            <div className='col-md-3 d-flex justify-content-center align-items-center '>
              <div className='camera-container mb-3 profileupdate'>
                <label htmlFor='photoupload' className='photouploadicon'>
                  {imageUploading ? (
                    'Loading...'
                  ) : (
                    <img
                      className='camera-img'
                      src={
                        userData?.profile_image
                          ? apiConstants.imgUrlS3 + userData?.profile_image // Fixed the syntax error by removing 'uri:'
                          : '/img/camera.png'
                      }
                      alt=''
                    />
                  )}
                </label>
                <label className='changeprofile' htmlFor='photoupload'>
                  {userData?.profile_image ? 'Change Image' : 'Add Image'}
                </label>
                <input
                  onChange={handleImageUpload}
                  type='file'
                  id='photoupload'
                  style={{ display: 'none' }}
                />
              </div>
            </div>
            <div className='col-md-9 '>
              {/* <div className='usernametitle'>{userData?.first_name}</div> */}
              <h4 className='ng-binding mb-5' style={{ textTransform: 'capitalize' }}>
                {userData?.first_name + userData?.last_name}
              </h4>
              <div className='row justify-content-between profileitem'>
                <div className='itemlabel col-md-6'>Organization</div>
                <div className='itemvalue col-md-6'>{userData?.company_name}</div>
              </div>
              <div className='row justify-content-between profileitem'>
                <div className='itemlabel col-md-6'>Name</div>
                <div className='itemvalue col-md-6'>{userData?.first_name}</div>
              </div>
              <div className='row justify-content-between profileitem'>
                <div className='itemlabel col-md-6'>Email Id</div>
                <div className='itemvalue col-md-6'>{userData?.email}</div>
              </div>
              <div className='row justify-content-between profileitem'>
                <div className='itemlabel col-md-6'>Phone Number</div>
                <div className='itemvalue col-md-6'>{userData?.phone}</div>
              </div>
              <div className='row justify-content-between'>
                <div className='itemlabel col-md-6'>Password</div>
                <div className='itemvalue col-md-6'>
                  <span
                    className='changepassword'
                    onClick={() => {
                      setChangePassword(true);
                    }}
                  >
                    {' '}
                    <SquarePen size={15} />
                    Change
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        isOpen={showChangePassword}
        onClose={() => setChangePassword(false)}
        Data={{ id: userData?.id }}
      />
    </div>
  );
}
