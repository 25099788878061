import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from '../_components/Loader';
import { apiConstants } from '../_constants';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { miscService, UploadS3UsingFetch, validateFileSize } from '../_services/misc.service';
import { userService } from '../_services/user.service';
import './user.css';

export const UserAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: null,
    username: '',
    password: '',
    confirmpassword: '',
    profile_image: '',
    role: ROLE.USER,
  });
  const [imageUploading, setImageUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id > 0) {
      getuserbyid(id);
    }
  }, []);

  const getuserbyid = async () => {
    setIsLoading(true);
    try {
      const query = {
        id: id,
      };
      const response = await userService.getUserByid(query);
      if (response.data) {
        setUserData(response.data);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == 'phone' && value.length > 10) {
      return;
    }

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleStatusChange = (status) => {
    setUserData((prev) => ({
      ...prev,
      status: status,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { first_name, email, phone, password, confirmpassword, username } = userData;
    if (password && confirmpassword && password !== confirmpassword) {
      toast.error('Passwords Does Not match!', toastConstant.TOAST_PAYLOAD);
      return;
    }

    if (!first_name || !phone || !email || !username || ((!id || id == 0) && !password)) {
      toast.warn('Please Complete Form!', toastConstant.TOAST_PAYLOAD);
      return;
    }
    try {
      setSubmitted(false);
      delete userData.confirmpassword;
      const response = await userService.addUser(userData);
      if (response) {
        navigate(-1);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    }
  };

  const handleImageUpload = async (event) => {
    setImageUploading(true);
    const file = event?.target?.files[0];

    const ext = file.name.split('.').pop();
    if (await validateFileSize(file?.size)) {
      const response = await miscService.createSignedUploadUrl({
        type: 'image',
        mime_type: file.type,
        ext,
      });

      const { signedUrl, filename } = response.data;
      const uploadResponse = await UploadS3UsingFetch(file, signedUrl);
      if (uploadResponse) {
        setUserData({ ...userData, profile_image: filename });
        setImageUploading(false);
      } else {
        setImageUploading(false);
        toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
      }
    } else {
      setImageUploading(false);

      toast.error('Image size is larger!!', toastConstant.TOAST_PAYLOAD);
    }
  };

  return (
    <div className='useradd'>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>
              {id == 0 ? 'Add User' : 'Edit User'} {isLoading && <Loader size={25} />}
            </div>
          </div>
        </div>
        <form name='form' onSubmit={handleSubmit}>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3 d-flex justify-content-center align-items-center '>
                <div className='camera-container mb-3'>
                  <label htmlFor='photoupload' className='photouploadicon'>
                    {imageUploading ? (
                      <Loader />
                    ) : (
                      <img
                        className='camera-img'
                        src={
                          userData?.profile_image
                            ? apiConstants.imgUrlS3 + userData?.profile_image // Fixed the syntax error by removing 'uri:'
                            : '/img/camera.png'
                        }
                        alt=''
                      />
                    )}
                  </label>
                  <input
                    onChange={handleImageUpload}
                    type='file'
                    id='photoupload'
                    style={{ display: 'none' }}
                    accept='image/*'
                  />
                </div>
              </div>

              <div className='col-md-4 '>
                <div
                  className={'form-group' + (submitted && !userData.first_name ? ' has-error' : '')}
                >
                  <input
                    onChange={handleChange}
                    type='text'
                    name='first_name'
                    value={userData.first_name}
                    className='form-control mb-3'
                  />
                  <span className='floating-label'>First Name</span>{' '}
                </div>
                <div className='form-group'>
                  <input
                    onChange={handleChange}
                    type='text'
                    name='last_name'
                    value={userData.last_name}
                    className='form-control mb-3'
                  />
                  <span className='floating-label'>Last Name (Optional)</span>{' '}
                </div>
                <div className={'form-group' + (submitted && !userData.email ? ' has-error' : '')}>
                  <input
                    onChange={handleChange}
                    className='form-control mb-3'
                    name='email'
                    value={userData.email}
                    type='email'
                  />
                  <span className='floating-label'>Email</span>{' '}
                </div>
                <div
                  className={'form-group' + (submitted && !userData.username ? ' has-error' : '')}
                >
                  <input
                    onChange={handleChange}
                    className='form-control mb-3'
                    name='username'
                    value={userData.username}
                    type='text'
                  />
                  <span className='floating-label'>Username</span>{' '}
                </div>
                <div className={'form-group' + (submitted && !userData.phone ? ' has-error' : '')}>
                  <input
                    onChange={handleChange}
                    className='form-control mb-3'
                    name='phone'
                    value={userData.phone}
                    type='number'
                    style={{ appearance: 'none', MozAppearance: 'textfield' }} // Added CSS to remove arrows
                  />
                  <span className='floating-label'>Phone Number</span>{' '}
                </div>
                <div
                  className={'form-group' + (submitted && !userData.password ? ' has-error' : '')}
                >
                  <input
                    onChange={handleChange}
                    className='form-control mb-3'
                    name='password'
                    value={userData.password}
                    type='password'
                  />
                  <span className='floating-label'>Password</span>{' '}
                </div>
                <div
                  className={
                    'form-group' + (submitted && !userData.confirmpassword ? ' has-error' : '')
                  }
                >
                  <input
                    onChange={handleChange}
                    className='form-control mb-3'
                    name='confirmpassword'
                    value={userData.confirmpassword}
                    type='password'
                  />
                  <span className='floating-label'>Confirm Password</span>{' '}
                  <div className='row'>
                    {id == 0 ? (
                      ''
                    ) : (
                      <div className='col-sm-12 mb-3'>
                        <div className='btn-group form-group'>
                          <button
                            className={`btn btn-primary btn-sm ${
                              userData?.status === 1 ? 'active' : ''
                            }`}
                            onClick={() => handleStatusChange(1)}
                            type='button'
                          >
                            Active
                          </button>
                          <button
                            className={`btn btn-primary btn-sm ${
                              userData?.status === 0 ? 'active' : ''
                            }`}
                            onClick={() => handleStatusChange(0)}
                            type='button'
                          >
                            Inactive
                          </button>
                        </div>
                      </div>
                    )}
                    <div className='col-sm-12'>
                      <button className='btn btn-primary btn-sm'>
                        {id == 0 ? 'Save' : 'Save changes'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
