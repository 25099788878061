/* eslint-disable no-unused-vars */
import { Eye, EyeOff } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { Loader } from '../_components/Loader';
import { userService } from '../_services/user.service';
import './login.css';

export function Login() {
  let navigate = useNavigate();
  const [logindata, setlogindata] = useState({
    email: '',
    password: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setlogindata({
      ...logindata,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { email, password } = logindata;

    if (email && password) {
      setIsLoading(true);
      try {
        const login = await userService.login(logindata);
        navigate('/');
      } catch (error) {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className=' login_container'>
      <div className='col-md-4 col-11'>
        <div className='login-box'>
          <div className='d-flex justify-content-between mb-3'>
            <div className='logo-box'>
              <img alt='Login' src='/img/logo.png' className='loginlogo' />
            </div>
            <h4 className=' align-content-center mb-0' style={{ textAlign: 'center' }}>
              Login
            </h4>
          </div>
          <span className='color-red'></span>
          <form name='form' onSubmit={handleSubmit}>
            <div
              className={
                'form-group position-relative' + (submitted && !logindata.email ? ' has-error' : '')
              }
            >
              <div className='txtmb mt-3 w-100'>
                <div style={{ flex: 1, display: 'flex' }}>
                  <input
                    name='email'
                    className='form-control '
                    type='text'
                    value={logindata.email}
                    onChange={handleChange}
                  />
                  <span className='floating-label'>Username / Email</span>
                </div>
              </div>
            </div>
            <div
              className={
                'form-group position-relative' +
                (submitted && !logindata.password ? ' has-error' : '')
              }
            >
              <div className='txtmb mt-3 w-100'>
                <div style={{ flex: 1, display: 'flex' }}>
                  <input
                    name='password'
                    className='form-control '
                    // type='password'
                    value={logindata.password}
                    onChange={handleChange}
                    type={showPassword ? 'text' : 'password'}
                  />
                  {showPassword ? (
                    <EyeOff className='eye' size={20} onClick={() => setShowPassword(false)} />
                  ) : (
                    <Eye className='eye' size={20} onClick={() => setShowPassword(true)} />
                  )}
                  <span className='floating-label'>Password</span>
                </div>
              </div>
            </div>
            <div className='form-group mt-3 d-flex justify-content-end '>
              <button className='btn btn-primary width-100 d-flex gap-3 justify-content-center'>
                {isLoading ? <Loader size={20} /> : 'Login'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
