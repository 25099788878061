import { List, Paperclip, Pencil, Plus } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pagination } from '../_components';
import { Loader } from '../_components/Loader';
import { NoData } from '../_components/NoData';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { manipulateOrderId } from '../_services/misc.service';
import { orderService } from '../_services/order.service';
import { useUser } from '../Hooks/useUser';

export const OrderList = () => {
  const localUser = useUser();
  const navigate = useNavigate();

  const [orderData, setOrderData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllOrders(1);
  }, []);

  const getAllOrders = async (pageNo) => {
    setIsLoading(true);
    if (pageNo) {
      query.pageVo.pageNo = pageNo;
      setQuery(query);
    }
    try {
      const data = await orderService.orderList(query);
      if (data.data.rows) {
        setOrderData(data.data.rows);
        setPaginationData({
          page: data.data.page,
          pages: data.data.pages,
          total: data.data.total,
        });
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const renderUserItem = () => {
    return orderData.map((order) => {
      const orderId = manipulateOrderId(order.orderref_id, 3, order?.order_details_index);

      return (
        <tr
          key={order?.id}
          className='cursor-pointer'
          onClick={() => {
            navigate('/ordersingle/' + order?.id + '/' + order?.order_id);
          }}
        >
          <td>{orderId}</td>
          <td>
            {order?.client_user} from {order.company}
          </td>
          <td>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <b className='ng-binding'>{moment(order?.createdAt).format('MMM D, YYYY')}</b>
              <span className='ng-binding'>{moment(order?.createdAt).format(' h:mm A')}</span>
            </div>
          </td>
          <td>
            <span className='ng-binding ng-scope'>{order?.job_title}</span>
            <br />
            <span>
              <small>
                Mobilization: <br />
                {moment(parseInt(order?.mobilization)).format('MMM D, YYYY')}
              </small>
            </span>
          </td>
          <td>{order?.noofjobs}</td>
          <td>{order?.CandidateJobDetails?.STATUS_UNVERIFIED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_UNREAD ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_MOBILIZED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_HIRED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_SHORTLISTED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_REJECTED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_WAITING ?? 0}</td>
          <td>{order?.CandidateJobDetails?.TOTAL ?? 0}</td>
          <td>
            <span className='activeicon'>{order?.status == 1 ? 'Active' : 'Inactive'}</span>
          </td>
          <td>
            <div className='jddesc'>{order?.description}</div>
            <a
              href={order?.jd}
              target='_blank'
              rel='noreferrer'
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Paperclip size={15} className='cursor-pointer' /> View
            </a>
          </td>
          {localUser?.role == ROLE.ADMIN && (
            <td>
              <Pencil
                color='grey'
                size={20}
                className='cursor-pointer'
                onClick={(event) => {
                  event.stopPropagation();
                  navigate('/orderedit/' + order?.order_id);
                }}
              />
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <div>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>
              <List />
              Orders
            </div>
            <div className='addbtncontainer'>
              {localUser?.role != ROLE.CLIENT_USER && (
                <div
                  className='adduserbtn'
                  onClick={() => {
                    navigate('/orderadd/0');
                  }}
                >
                  <Plus size={15} />
                  Order
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th>Order #</th>
                <th>Client</th>
                <th>Created On</th>
                <th>Position</th>
                <th>Total Requirements</th>
                <th>Unverified</th>
                <th>New</th>
                <th>Total Mobilized</th>
                <th>Please Mobilize</th>
                <th>Interview</th>
                <th>Rejected</th>
                <th>Waiting List</th>
                <th>Total CV uploaded</th>
                <th>Status</th>
                <th>View Job Description</th>
                {localUser?.role == ROLE.ADMIN && <th></th>}
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {orderData.length == 0 && !isLoading && <NoData />}
          {!!isLoading && orderData.length == 0 && <Loader />}

          <Pagination
            OnClickPage={(pageNo) => {
              getAllOrders(pageNo);
            }}
            demoData={paginationData}
          />
        </div>
      </div>
    </div>
  );
};
