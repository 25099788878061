import { Building2 } from 'lucide-react';
import React from 'react';
import { manipulateOrderId } from '../_services/misc.service';

export const RelatedOrders = ({ orderList, onClick, selected }) => {
  return (
    <div>
      <div className='orderlistcontainer mt-3'>
        {orderList?.map((order, index) => {
          const orderId = manipulateOrderId(order.orderref_id, 3, order?.order_details_index);
          const isSelected = parseInt(order.id) == parseInt(selected);
          return (
            <div
              className={'orderitem ' + (isSelected ? 'selectedOrder' : '')}
              onClick={() => onClick(order)}
              key={index}
            >
              <div className='ordertitle'>
                <Building2 size={15} /> ({orderId})
              </div>
              <div className='ordercontent'>
                <div className='ordercountitem'>
                  <div className='countlbl'>{order?.noofjobs}</div>
                  <div className='counttxt'>Required</div>
                </div>
                <div className='ordercountitem'>
                  <div className='countlbl'>{order?.candidateCounts?.STATUS_HIRED ?? 0}</div>
                  <div className='counttxt'>Please Mobilize</div>
                </div>
                <div className='ordercountitem'>
                  <div className='countlbl'>{order?.candidateCounts?.STATUS_UNREAD ?? 0}</div>
                  <div className='counttxt'>New</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
