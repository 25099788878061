import { X } from 'lucide-react';
import React, { useState } from 'react';
import './modal.css'; // Ensure you have some basic styles for the modal

const GuestUserModal = ({ visible, onClose, onSuccess }) => {
  const [guestname, setGuestname] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;

    setGuestname(value);
  };

  const handleSubmit = async () => {
    try {
      setSubmitted(true);
      if (guestname.length > 0) {
        localStorage.setItem('Guest', guestname);
        onSuccess();
      }
    } catch (err) {
      console.log(err, 'this is error in catch');
    } finally {
      setSubmitted(false);
    }
  };
  if (!visible) return null;

  return (
    <div className='modal fade-in' style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}>
      <div
        className='modal-dialog h-100 d-flex justify-content-center align-items-center'
        style={{ maxWidth: 650 }}
      >
        <div className='modal-content'>
          <div className='closebtn' onClick={onClose}>
            <X />
          </div>
          <div className='modal-body' style={{ textAlign: 'left' }}>
            <h3>Hi,</h3>
            <div className='modalcontent'>Please enter your name to continue</div>

            <div
              className={
                'form-group position-relative mt-2' + (submitted && !guestname ? ' has-error' : '')
              }
            >
              <input
                name='guest_name'
                className='form-control  '
                type='text'
                value={guestname}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              />
              <span className='floating-label'>Full Name</span>
            </div>

            <div className='d-flex justify-content-left gap-2 mt-4'>
              <div
                className='submitbtn'
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestUserModal;
