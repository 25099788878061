import { Loader } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pagination } from '../_components';
import { NoData } from '../_components/NoData';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { notificationService } from '../_services/notification.service';
import { useUser } from '../Hooks/useUser';

export const NotificationList = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllNotification(1);
  }, []);

  const getAllNotification = async (pageNo) => {
    try {
      setIsLoading(true);
      if (pageNo) {
        query.pageVo.pageNo = pageNo;
        setQuery(query);
      }

      const res = await notificationService.notificationList(query);

      if (res.data.rows) {
        setNotificationList(res.data.rows);
        setPaginationData({
          page: res.data.page,
          pages: res.data.pages,
          total: res.data.total,
        });
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigation = (notification) => {
    if (notification.mode == 1) {
      navigate('/ordersingle/' + notification?.orderDetails_id + '/' + notification?.order_id);
    } else if (notification.mode == 2 || notification?.mode == 3) {
      navigate('/candidatesingle/' + notification?.candidate_id);
    } else {
      toast.warn('No Data Found', toastConstant.TOAST_PAYLOAD);
    }
    ChangeToRead(notification?.id);
  };

  const ChangeToRead = async (id) => {
    try {
      const query = {
        id: id,
        is_read: 1,
      };

      if (user?.role == ROLE.ADMIN) {
        query.is_read_admin = 1;
        delete query?.is_read;
      }

      await notificationService.addNotification(query);
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    }
  };

  const renderUserItem = () => {
    return notificationList?.map((notification, index) => {
      return (
        <tr
          key={notification?.id}
          onClick={() => {
            handleNavigation(notification);
          }}
          className='cursor-pointer'
        >
          <td>{(query.pageVo.pageNo - 1) * query.pageVo.noOfItems + index + 1}</td>
          <td>{notification?.note}</td>
          <td>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <b className='ng-binding'>{moment(notification?.createdAt).format('MMM D, YYYY')}</b>
              <span className='ng-binding'>
                {moment(notification?.createdAt).format(' h:mm A')}
              </span>
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>Notifications</div>
            <div className='addbtncontainer'></div>
          </div>
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th></th>
                <th>Notification</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {notificationList.length == 0 && !isLoading && <NoData />}
          {!!isLoading && <Loader />}

          <Pagination
            OnClickPage={(pageNo) => {
              getAllNotification(pageNo);
            }}
            demoData={paginationData}
          />
        </div>
      </div>
    </div>
  );
};
