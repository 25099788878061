import { BriefcaseBusiness, Building } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../Hooks/useUser';
import { ROLE } from '../_constants/role.constants';

function NavBar() {
  const navigate = useNavigate();
  const localUser = useUser();

  let navtabs = [
    { label: 'Dashboard', url: '/dashboard', icon: '/img/dashboard.png' },
    { label: 'Madre Team', url: '/users', icon: '/img/usercardicon.png' },
    { label: 'Client Users', url: '/clients', icon: '/img/clients.png' },
    { label: 'Orders', url: '/orders', icon: '/img/listicon.png' },
    { label: 'Candidate', url: '/candidates', icon: '/img/singleuser.png' },
    { label: 'Settings', url: null, icon: '/img/setting.png' },
  ];

  if (localUser?.role == ROLE.USER) {
    navtabs = navtabs.filter((tab) => tab.label !== 'Madre Team'); // Remove 'Madre Team' for non-admins
  }
  if (localUser?.role == ROLE.CLIENT_USER) {
    navtabs = [
      { label: 'Dashboard', url: '/dashboard', icon: '/img/dashboard.png' },
      { label: 'Orders', url: '/orders', icon: '/img/listicon.png' },
    ];
  }

  const location = useLocation();

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const optionsRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowMoreOptions(false); // Hide options if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside); // Add event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup
    };
  }, []);

  return (
    <div className='container-fluid p-0'>
      <div className='navcontainer'>
        <ul className='d-flex p-0 flex-wrap mb-0'>
          {navtabs.map(({ label, url, icon }, index) => (
            <li
              className={`navitem ${
                location.pathname === url || (url == '/dashboard' && location.pathname.includes())
                  ? 'active'
                  : '' + (label == 'Settings' && ' position-relative')
              }`} // Fixed the className logic
              key={index}
              onClick={() => {
                if (label == 'Settings') {
                  setShowMoreOptions(!showMoreOptions);
                } else {
                  navigate(url);
                }
              }}
            >
              <img src={icon} className='navitemicon' />
              <a className='navitemtxt'>{label}</a>

              {showMoreOptions && label == 'Settings' && (
                <div className='moreoptions' ref={optionsRef}>
                  <div
                    className='optionitem'
                    onClick={() => {
                      navigate('/jobroles');
                    }}
                  >
                    {' '}
                    <BriefcaseBusiness size={20} />
                    Job Title
                  </div>
                  <div
                    className='optionitem'
                    onClick={() => {
                      navigate('/companies');
                    }}
                  >
                    {' '}
                    <Building size={20} />
                    Client
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default NavBar;
