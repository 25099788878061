import React, { useEffect, useState } from 'react';
import { dashboardService } from '../_services/dashboard.service';

export const DashboradCounts = () => {
  const [dashboardCount, setDashboardCount] = useState({});

  useEffect(() => {
    getDashboardReport();
  }, []);

  const getDashboardReport = async () => {
    try {
      const dashboardData = await dashboardService.getDashboardCount();
      if (dashboardData?.data?.length) {
        setDashboardCount(dashboardData.data[0]);
      }
    } catch (error) {
      console.log(error);
      // toast.error(error.message, toastConstant.TOAST_PAYLOAD);
    }
  };
  return (
    <div className='countbox'>
      <div className='countcard'>
        <div className='card_content'>
          <div className='cardcount'>{dashboardCount?.noofjobs ?? 0}</div>
          <div className='cardhead'>Total Requirements</div>
        </div>
        <div className='card_icon'>
          <img src='/img/listed.png' alt='' className='dashcount_icon' />
        </div>
      </div>
      <div className='countcard'>
        <div className='card_content'>
          <div className='cardcount'>{dashboardCount?.STATUS_HIRED ?? 0}</div>
          <div className='cardhead'>Please Mobilize</div>
        </div>
        <div className='card_icon'>
          <img src='/img/selected.png' alt='' className='dashcount_icon' />
        </div>
      </div>
      <div className='countcard border-0'>
        <div className='card_content'>
          <div className='cardcount'>{dashboardCount?.TOTAL_COUNT ?? 0}</div>
          <div className='cardhead'>Total CV&apos;s Submitted</div>
        </div>
        <div className='card_icon'>
          <img src='/img/unread.png' alt='' className='dashcount_icon' />
        </div>
      </div>
    </div>
  );
};
