import React from 'react';

export const CandidateCV = ({ cvurl }) => {
  const getExtentionFromUrl = (url) => {
    return url?.split('.').pop();
  };

  const getGoogleDocView = (url) => {
    // Implement your getGoogleDocView logic here
    return url; // Placeholder
  };

  return (
    <div className='row'>
      <div className='col-md-12'>
        {getExtentionFromUrl(cvurl) === 'pdf' ? (
          <iframe
            src={cvurl}
            type='application/pdf' // Added type attribute
            width='100%'
            height='600px'
            title='Candidate CV'
            style={{ backgroundColor: '#fff', overflow: 'hidden', border: 'none' }}
          />
        ) : (
          <iframe
            src={getGoogleDocView(cvurl)}
            style={{ width: '100%', height: '100vh' }}
            title='Candidate CV'
          />
        )}
      </div>
    </div>
  );
};
