import { File, X } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { getFileType } from '../_services/misc.service';

export const FilePreview = ({ file, handleRemove }) => {
  const [localFile, setLocalFile] = useState('');

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const fileURI = reader.result; // This is the data URL
      setLocalFile(fileURI);
      // You can now use fileURI to display the image or for other purposes
    };
    reader.readAsDataURL(file);
  }, [file]);

  return (
    <div className='previewcontainer'>
      <div className='closebtn' onClick={handleRemove}>
        <X />
      </div>
      {getFileType(file?.type) == 'image' && <img src={localFile} className='previewimg' />}

      {getFileType(file?.type) == 'document' && (
        <div className='pdfbox'>
          <File size={25} color='#858796' />
          <label htmlFor=''>{file?.name}</label>
        </div>
      )}
    </div>
  );
};
