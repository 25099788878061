import React, { useEffect, useState } from 'react';

export const Pagination = ({ OnClickPage, demoData }) => {
  const [paginationItems, setPaginationItems] = useState([]);
  const pageOnClick = (data) => {
    if (pageOnClick) {
      OnClickPage(data);
    }
  };

  const setListAndPagination = (demoData) => {
    if (!demoData) return;

    let paginationItems = [];
    if (demoData && demoData.pages > 1) {
      let start = 1;
      let end = demoData.pages;
      let currentPageNo = demoData.page;
      let totalPages = demoData.pages;
      if (totalPages > 5) {
        if (currentPageNo + 4 > totalPages) {
          start = totalPages - 4;
          end = totalPages;
        } else {
          if (currentPageNo == end) {
            start = currentPageNo;
            end = parseInt(currentPageNo) + 4;
          } else if (currentPageNo == 1) {
            start = currentPageNo;
            end = start + 4;
          } else if (currentPageNo == start) {
            end = currentPageNo;
            start = currentPageNo - 4;
            if (currentPageNo - 4 <= 0) {
              start = 1;
              end = 5;
            }
          } else if (currentPageNo < start) {
            end = start;
            start = end - 4;
          } else if (currentPageNo > start) {
            if (currentPageNo - 2 <= 0) {
              start = 1;
              end = 5;
            } else {
              start = currentPageNo - 2;
              end = currentPageNo + 2;
            }
          } else if (!end && !start) {
            start = 1;
            end = 5;
          }
        }
      }
      paginationItems.push(
        <li
          key='prev'
          className={'page-item nextprevicon ' + (currentPageNo <= 1 ? 'disabled' : '')}
          onClick={() => {
            if (currentPageNo > 1) {
              pageOnClick(currentPageNo - 1);
            }
          }}
        >
          <img src='/img/prev.png' className='nextprev' />
        </li>,
      );
      for (let i = start; i <= end; i = i + 1) {
        paginationItems.push(
          <li
            onClick={() => pageOnClick(i)}
            className={'page-item ' + (currentPageNo === i ? 'activepage ' : 'noactive')}
            key={i}
          >
            <a className={'paginationtext ' + (currentPageNo === i ? 'activepage ' : 'noactive')}>
              {i}
            </a>
          </li>,
        );
      }
      paginationItems.push(
        <li
          key='next'
          className={'page-item nextprevicon ' + (currentPageNo >= totalPages ? 'disabled' : '')}
          onClick={() => {
            if (totalPages > currentPageNo) {
              pageOnClick(currentPageNo + 1);
            }
          }}
        >
          <img src='/img/next.png' className='nextprev' />
        </li>,
      );
    }

    setPaginationItems(paginationItems);
  };

  useEffect(() => {
    setListAndPagination(demoData);
  }, [demoData]);

  return <ul className='pagination d-flex mt-3 mb-3'> {paginationItems}</ul>;
};
