import { CircleUser, LogOut } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiConstants } from '../_constants';
import { logout } from '../_helpers';
import { userAbb } from '../_services/misc.service';
import { useUser } from '../Hooks/useUser';
import './component.css';

export function Header() {
  const localuser = useUser();
  const navigate = useNavigate();
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const optionsRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowMoreOptions(false); // Hide options if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside); // Add event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup
    };
  }, []);
  return (
    <div className='head_container'>
      <a>
        <img src='/img/logo.png' className='headerlogo' />
      </a>
      <p className='slogan mb-0'>&quot; Nothing But Quality In Agile Delivery System &quot;</p>
      <div className='profileiconcontainer d-flex gap-3 align-items-center position-relative'>
        <div
          // to='/profile'
          className='text-decoration-none d-flex align-items-center gap-3 cursor-pointer'
          onClick={() => {
            setShowMoreOptions(!showMoreOptions);
          }}
        >
          {localuser?.profile_image ? (
            <img
              className='main_menu_profile'
              src={
                localuser?.profile_image
                  ? apiConstants.imgUrlS3 + localuser?.profile_image
                  : '/img/user_dummy.png'
              }
            />
          ) : (
            <div className='main_menu_abb'>
              {userAbb(localuser?.first_name + ' ' + localuser?.last_name)}
            </div>
          )}
          <div className='greettext'>Hi,{localuser?.first_name}</div>

          {showMoreOptions && (
            <div className='moreoptions' style={{ top: '63px', right: '10px' }} ref={optionsRef}>
              <div
                className='optionitem'
                onClick={() => {
                  navigate('profile');
                }}
              >
                {' '}
                <CircleUser size={15} />
                Profile
              </div>
              <div
                className='optionitem'
                onClick={() => {
                  logout();
                  // navigate('/companies');
                }}
              >
                {' '}
                <LogOut size={15} />
                Log out
              </div>
            </div>
          )}
        </div>
        <div className='veritcalline'></div>
        <img src='/img/unglobal.png' alt='' className='globalicon' />
      </div>
    </div>
  );
}
