import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { store } from '../src/_helpers';
import Router from './Router/Router';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Provider store={store}>
        <Router />
      </Provider>
      <ToastContainer />
    </>
  );
}

export default App;
