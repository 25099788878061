import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const dashboardService = {
    logout,
    getDashboardCount,

};



function getDashboardCount(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getdashboardreport', requestOptions).then(handleResponse);
}


