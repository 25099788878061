import React from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from './Header';
import NavBar from './NavBar';
import { ProtectedRoute } from './PrivateRoute';

export function RootLayout() {
  return (
    <>
      <ProtectedRoute>
        <div className='global_container'>
          <Header />
          <NavBar />
          <div className='outlet_contain'>
            <Outlet />
          </div>
        </div>
      </ProtectedRoute>
    </>
  );
}
