import { Logs } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../_components/Loader';
import { NoData } from '../_components/NoData';
import { ROLE } from '../_constants/role.constants';
import { manipulateOrderId } from '../_services/misc.service';
import { orderService } from '../_services/order.service';
import { useUser } from '../Hooks/useUser';

export const DashboardOrderList = () => {
  const navigate = useNavigate();
  const user = useUser();
  const [orderData, setOrderData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllOrders(1);
  }, []);

  const getAllOrders = async (pageNo) => {
    setIsLoading(true);
    if (pageNo) {
      query.pageVo.pageNo = pageNo;
      setQuery(query);
    }
    try {
      const data = await orderService.orderList(query);
      if (data.data.rows) {
        setOrderData(data.data.rows);
      }
    } catch (err) {
      // toast.error(err.message, toastConstant.TOAST_PAYLOAD);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderData = () => {
    return orderData?.map((order) => {
      const orderId = manipulateOrderId(order.orderref_id, 3, order?.order_details_index);

      return (
        <tr
          className='cursor-pointer'
          key={order?.id}
          onClick={() => {
            navigate('/ordersingle/' + order?.id + '/' + order?.order_id);
          }}
        >
          <td>{orderId}</td>
          {user?.role !== ROLE.CLIENT_USER && (
            <td>
              {order?.client_user} from {order.company}
            </td>
          )}
          <td>
            <span className='ng-binding ng-scope'>{order?.job_title}</span>
            <br />
            <span>
              <small>
                Mobilization: <br />
                {moment(parseInt(order?.mobilization)).format('MMM D, YYYY')}
              </small>
            </span>
          </td>
          <td>{order?.noofjobs}</td>
          <td>{order?.CandidateJobDetails?.STATUS_UNVERIFIED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_UNREAD ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_MOBILIZED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_HIRED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_SHORTLISTED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.STATUS_REJECTED ?? 0}</td>
          <td>{order?.CandidateJobDetails?.TOTAL ?? 0}</td>
        </tr>
      );
    });
  };
  return (
    <div className='dashorderlistcontainer'>
      <div className='card box-border  ' style={{ minHeight: '70vh' }}>
        <div className='card-header d-flex align-items-center gap-2 small-header'>
          <Logs /> New Orders
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover ' id='dataTable'>
            <thead>
              <tr>
                <th colSpan='1'>Order#</th>
                {user?.role !== ROLE.CLIENT_USER && <th>Client</th>}
                <th colSpan='1'>Position </th>
                <th className='text-center'>Total Requirements</th>
                <th className='text-center'>Unverified </th>
                <th className='text-center'>New </th>
                <th className='text-center'>Total Mobilized</th>
                <th className='text-center'>Please mobilize</th>
                <th className='text-center'>Shortlisted</th>
                <th className='text-center'>Rejected</th>

                <th className='text-center'>Total CV&apos;s Submitted</th>
              </tr>
            </thead>
            <tbody>{renderData()}</tbody>
          </table>
          {!isLoading && orderData.length == 0 && <NoData />}
          {!!isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};
