import React from 'react';
import { Loader } from '../_components/Loader';
import './chat.css';
import { NoteItem } from './NoteItem';

export const Notes = ({ notesList, isLoading }) => {
  return (
    <div className='notescontainer'>
      {!isLoading &&
        notesList?.map((note, index) => {
          return <NoteItem key={index} note={note} />;
        })}
      {!isLoading && notesList.length == 0 && <div className='nochats'>No Comments Found</div>}
      {isLoading && <Loader />}
    </div>
  );
};
