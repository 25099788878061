import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { userService } from '../_services/user.service';
import GuestUserModal from './GuestUserModal';

export const Redirection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const order_id = queryParams.get('order_id');
  const order_details_id = queryParams.get('id');

  if (token) {
    queryParams.delete('token');
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }

  const [showModal, setShowModal] = useState(false);

  // Use the token as needed

  useEffect(() => {
    getUserByToken(token);
  }, [token]);

  const getUserByToken = async (token) => {
    try {
      const data = await userService.getUserByToken(token);
      if (data?.data) {
        data.data.token = token;

        // login successful if there's a jwt token in the response
        if (data.data.token) {
          if (localStorage.getItem('user')) {
            const localuser = JSON.parse(localStorage.getItem('user'));
            console.log(localuser?.id, 'and', data?.data?.id);
            if (localuser.id === data.data.id) {
              setTimeout(() => {
                setShowModal(true);
              }, 2000);
            } else {
              localStorage.removeItem('user');
              navigate('/login');
            }
          } else {
            localStorage.setItem('user', JSON.stringify(data.data));

            setTimeout(() => {
              setShowModal(true);
            }, 2000);
          }
          // store user details and jwt token in local storage to keep user logged in between page refreshes
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log('finally ');
    }
  };

  const handleNext = () => {
    if (order_details_id && order_id) {
      navigate('/ordersingle/' + order_details_id + '/' + order_id);
    } else {
      navigate('/');
    }
  };

  return (
    <div>
      <div id='load'>
        <div>G</div>
        <div>N</div>
        <div>I</div>
        <div>D</div>
        <div>A</div>
        <div>O</div>
        <div>L</div>
      </div>
      <GuestUserModal
        visible={showModal}
        onSuccess={handleNext}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};
