import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const notificationService = {
    logout,
    addNotification,
    notificationList

};

function addNotification(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/addnotification', requestOptions).then(handleResponse);
}
function notificationList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/notificationlist', requestOptions).then(handleResponse);
}


