import React from 'react';
import { Loader } from '../_components/Loader';
import { useUser } from '../Hooks/useUser';
import './chat.css';
import { ChatMessage } from './ChatMessage';

export const ChatList = ({ chatList, isLoading }) => {
  const user = useUser();

  // ];
  return (
    <div className='chatlistcontainer'>
      {!isLoading &&
        chatList?.map((chat, index) => {
          return <ChatMessage chat={chat} key={index} userId={user?.id} />;
        })}
      {!isLoading && chatList.length == 0 && <div className='nochats'>No Chats Found</div>}
      {isLoading && <Loader />}
    </div>
  );
};
