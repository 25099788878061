import { Dot, File } from 'lucide-react';
import React from 'react';
import { apiConstants } from '../_constants';
import { timeAgo } from '../_services/misc.service';

export const NoteItem = ({ note }) => {
  const urlPattern = /https?:\/\/[^\s]+/;

  return (
    <div className=' d-flex gap-3 '>
      <img
        src={
          note?.profile_image ? apiConstants.imgUrlS3 + note.profile_image : '/img/user_dummy.png'
        }
        className='userimg'
      />
      <div className='messageitem w-100'>
        <h6>
          {note.first_name} <Dot color='GREY' />
          <span className='notetimeago'>{timeAgo(note.createdAt)}</span>
        </h6>
        {note.mode == 1 && (
          <div className={'position-relative noteitem '}>
            <span>
              {note?.message.split(/(https?:\/\/[^\s]+)/g).map((part, index) =>
                urlPattern.test(part) ? (
                  <a
                    key={index}
                    href={part}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='linkmsg'
                  >
                    {part}
                  </a>
                ) : (
                  part
                ),
              )}
            </span>
          </div>
        )}
        {note.mode == 3 && (
          <div
            className='shared-file cursor-pointer notefileitem'
            onClick={() => {
              window.open(note?.fileurl, '_blank');
            }}
          >
            <File color='#000' />
            <span className='file-name'>Document</span>
          </div>
        )}
      </div>
    </div>
  );
};
