import moment from 'moment';
import React from 'react';

export const ActionHistory = ({ history }) => {
  const statusOptions = [
    {
      id: 1,
      status: 1,
      label: 'Verify',
    },
    {
      id: 2,
      status: 5,
      label: 'Please mobilize',
    },
    {
      id: 3,
      status: 4,
      label: 'Online Interview',
      interview: true,
      interview_mode: 1,
    },
    {
      id: 4,
      status: 4,
      label: 'Telephonic Interview',
      interview: true,
      interview_mode: 2,
    },
    {
      id: 5,
      status: 4,
      label: 'Face to face Interview',
      interview: true,
      interview_mode: 3,
    },
    {
      id: 6,
      status: 7,
      label: 'Move to Waiting List',
    },
    {
      id: 7,
      status: 3,
      label: 'Reject',
    },
    {
      id: 8,
      status: 6,
      label: 'Mobilized',
    },
    {
      id: 9,
      status: 8,
      label: 'Interview',
    },
  ];
  const getLabelByStatus = (status, interview_mode) => {
    const option = statusOptions.find((option) => {
      if (status === 4) {
        return option.interview_mode === interview_mode; // Check interview_mode for status 4
      } else {
        return option.status === status; // Check status for other cases
      }
    });
    return option ? option.label : 'Select'; // Return the label or null if not found
  };
  const renderUserItem = () => {
    return history?.map((item) => {
      return (
        <tr key={item?.id}>
          <td>{getLabelByStatus(item?.status, item?.interviewmode)}</td>
          <td>{moment(item?.date).format('YYYY-MMM-DD - hh:mm a')}</td>
          <td>{item?.guestuser ? `${item?.guestuser} for ${item?.name}` : item?.name}</td>
        </tr>
      );
    });
  };
  return (
    <div>
      <div className='card-body table-responsive mt-5'>
        <h5 style={{ color: '#858796' }}>History</h5>
        <table className='table table-inbox table-hover'>
          <thead>
            <tr>
              <th>Action</th>
              <th>Date</th>
              <th>Done By</th>
            </tr>
          </thead>
          <tbody>{renderUserItem()}</tbody>
        </table>
      </div>
    </div>
  );
};
