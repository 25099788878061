import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const userService = {
  login,
  logout,
  register,
  usersList,
  saveUser,
  updateprofile,
  changePassword,
  addUser,
  resetPassword,
  getUserByid,
  getUserByToken
};

function login(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  return fetch(apiConstants.apiUrl + '/login', requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
      }

      return user;
    });
}


function usersList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getuserList', requestOptions).then(handleResponse);
}
function getUserByid(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getuserbyid', requestOptions).then(handleResponse);
}

function saveUser(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/saveuser', requestOptions).then(handleResponse);
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/register', requestOptions).then(handleResponse);
}

function updateprofile(user) {

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/updateprofile', requestOptions).then(handleResponse);
}


function changePassword(password) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(password),
  };

  return fetch(apiConstants.apiUrl + '/changePassword', requestOptions).then(handleResponse);
}


function resetPassword(userdata) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(userdata),
  };

  return fetch(apiConstants.apiUrl + '/resetPassword', requestOptions).then(handleResponse);
}


function addUser(userData) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(userData),
  };

  return fetch(apiConstants.apiUrl + '/adduser', requestOptions).then(handleResponse);
}

function getUserByToken(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'x-access-token': token,
      'Content-Type': 'application/json'
    },
  };
  return fetch(apiConstants.apiUrl + '/getuserbytoken', requestOptions).then(handleResponse);
}
