import { IdCard, Plus, UserRoundPen } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pagination } from '../_components';
import { Loader } from '../_components/Loader';
import { NoData } from '../_components/NoData';
import { apiConstants } from '../_constants';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { userService } from '../_services/user.service';
import { useUser } from '../Hooks/useUser';

export const ClientUserList = () => {
  const localUser = useUser();
  const navigate = useNavigate();

  const [clientsData, setClientsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    role: 3,
  });

  useEffect(() => {
    getAllClientsList(1);
  }, []);

  const getAllClientsList = async (pageNo, searchkey) => {
    if (pageNo) {
      query.pageVo.pageNo = pageNo;
    }
    if (searchkey) {
      query.searchKey = searchkey;
    } else {
      query.searchKey = '';
    }
    setIsLoading(true);
    setQuery(query);
    try {
      const response = await userService.usersList(query);

      if (response.data.rows) {
        setPaginationData({
          page: response.data.page,
          pages: response.data.pages,
          total: response.data.total,
        });

        setClientsData(response.data.rows);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    getAllClientsList(1, value);
  };

  const renderUserItem = () => {
    return clientsData.map((client) => {
      return (
        <tr key={client?.id}>
          <td>
            <img
              className='userimgicon'
              src={
                client?.profile_image
                  ? apiConstants.imgUrlS3 + client?.profile_image
                  : '/img/user_dummy.png'
              }
            />
          </td>
          <td>{client?.first_name}</td>
          <td>{client?.Company}</td>
          <td>{client?.username}</td>
          <td>
            <span className='activeicon'>{client?.status == 1 ? 'Active' : 'Inactive'}</span>
          </td>
          {localUser?.role == ROLE.ADMIN && (
            <td>
              <UserRoundPen
                color='grey'
                size={20}
                className='cursor-pointer'
                onClick={() => navigate('/clientsadd/' + client?.id)}
              />
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <div>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>
              <IdCard />
              Client Users
            </div>
            <div className='searchbarcontainer'>
              <input
                type='text'
                className='searchbar'
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder='Search Client User'
              />
            </div>
            <div className='addbtncontainer'>
              <div
                className='adduserbtn'
                onClick={() => {
                  navigate('/clientsadd/0');
                }}
              >
                <Plus size={15} strokeWidth={3} />
                Client
              </div>
            </div>
          </div>
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Name</th>
                <th>Client</th>
                <th>Username</th>
                <th>status</th>
                {localUser?.role == ROLE.ADMIN && <th></th>}
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {clientsData.length == 0 && !isLoading && <NoData />}
          {!!isLoading && clientsData.length == 0 && <Loader />}

          <Pagination
            OnClickPage={(pageNo) => {
              getAllClientsList(pageNo);
            }}
            demoData={paginationData}
          />
        </div>
      </div>
    </div>
  );
};
