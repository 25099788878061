import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const messageService = {
    logout,
    getMessageById,
    addMessage,
    messageList

};



function getMessageById(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getmessagebyid', requestOptions).then(handleResponse);
}
function addMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/addmessage', requestOptions).then(handleResponse);
}
function messageList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/messagelist', requestOptions).then(handleResponse);
}


