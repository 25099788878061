import { BriefcaseBusiness, Plus, UserRoundPen } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pagination } from '../_components';
import { toastConstant } from '../_constants/toast.constants';
import { jobService } from '../_services/job.service';

export const JobRoleList = () => {
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllJobRoles(1);
  }, []);

  const getAllJobRoles = async (pageNo) => {
    setIsLoading(true);

    if (pageNo) {
      query.pageVo.pageNo = pageNo;
      setQuery(query);
    }

    try {
      const response = await jobService.jobRoleList(query);
      if (response?.data?.rows) {
        setJobList(response.data.rows);
      }
      setPaginationData({
        page: response?.data?.page,
        pages: response?.data?.pages,
        total: response?.data?.total,
      });
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const renderUserItem = () => {
    return jobList.map((jobrole) => {
      return (
        <tr key={jobrole?.id}>
          <td>{jobrole?.name}</td>
          <td>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <b className='ng-binding'>{moment(jobrole?.createdAt).format('MMM D, YYYY')}</b>
              <span className='ng-binding'>{moment(jobrole?.createdAt).format(' h:mm A')}</span>
            </div>
          </td>
          <td>
            <span className='activeicon'>{jobrole?.status == 1 ? 'Active' : 'Inactive'}</span>
          </td>
          <td>
            <UserRoundPen
              color='grey'
              size={20}
              className='cursor-pointer'
              onClick={() => navigate('/jobrolesadd/' + jobrole?.id)}
            />
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>
              <BriefcaseBusiness size={20} />
              Job Title
            </div>
            <div className='addbtncontainer'>
              <div
                className='adduserbtn'
                onClick={() => {
                  navigate('/jobrolesadd/0');
                }}
              >
                <Plus size={15} strokeWidth={3} />
                Job Title
              </div>
            </div>
          </div>
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Created on</th>
                <th>status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {jobList.length == 0 && !isLoading && <div className='nodata'>No Job Title Found</div>}
          <Pagination
            OnClickPage={(pageNo) => {
              getAllJobRoles(pageNo);
            }}
            demoData={paginationData}
          />
        </div>
      </div>
    </div>
  );
};
