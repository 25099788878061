import { UserPen } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Loader } from '../_components/Loader';
import { apiConstants } from '../_constants';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { authHeader } from '../_helpers';
import { miscService, UploadS3UsingFetch, validateFileSize } from '../_services/misc.service';
import { userService } from '../_services/user.service';
import './client.css';

export const ClientUserAdd = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [clientData, setClientData] = useState({
    first_name: '',
    last_name: '',
    email: null,
    username: '',
    password: '',
    confirmpassword: '',
    profile_image: '',
    company_id: null,
    role: ROLE.CLIENT_USER,
  });
  const [imageUploading, setImageUploading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id > 0) {
      getuserById(id);
    }
  }, [id]);

  const getuserById = async (id) => {
    const query = {
      id: id,
    };
    try {
      setIsLoading(true);
      const response = await userService.getUserByid(query);

      if (response.data) {
        setClientData(response.data);
        setSelectedCompany(response?.data?.company_name);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setClientData({
      ...clientData,
      [name]: value,
    });
  };

  const handleStatusChange = (status) => {
    setClientData((prev) => ({
      ...prev,
      status: status,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const { first_name, password, confirmpassword, username, company_id } = clientData;
    if (password && confirmpassword && password !== confirmpassword) {
      toast.error('Passwords Does Not match!', toastConstant.TOAST_PAYLOAD);
      return;
    }

    if (!first_name || !company_id || !username || ((!id || id == 0) && !password)) {
      toast.warn('Please Complete Form!', toastConstant.TOAST_PAYLOAD);
      return;
    }
    try {
      setSubmitted(false);

      delete clientData.confirmpassword;
      const response = await userService.addUser(clientData);
      if (response) {
        navigate(-1);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    }
  };

  const handleImageUpload = async (event) => {
    setImageUploading(true);
    const file = event?.target?.files[0];

    const ext = file.name.split('.').pop();
    if (await validateFileSize(file?.size)) {
      const response = await miscService.createSignedUploadUrl({
        type: 'image',
        mime_type: file.type,
        ext,
      });

      const { signedUrl, filename } = response.data;
      const uploadResponse = await UploadS3UsingFetch(file, signedUrl);
      if (uploadResponse) {
        setClientData({ ...clientData, profile_image: filename });
        setImageUploading(false);
      } else {
        setImageUploading(false);
        toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
      }
    } else {
      setImageUploading(false);

      toast.error('Image size is larger!!', toastConstant.TOAST_PAYLOAD);
    }
  };

  const getCompanies = (searchKey) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getallcompanies', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: searchKey,
          include: ['id', 'company_name'],
        }),
      })
        .then((response) =>
          response.json().then((data) => {
            resolve(
              data.data.map(({ id, company_name }) => {
                return {
                  value: id,
                  label: company_name,
                };
              }),
            );
          }),
        )
        .catch((err) => {
          reject(err);
        });
    });

  const handleChangeCompany = (selectedOption) => {
    const { value, label } = selectedOption;
    setClientData((prev) => ({
      ...prev,
      company_id: value,
    }));

    setSelectedCompany(label);
  };

  return (
    <div className='useradd'>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-sm-flex align-items-center justify-content-between'>
            <div className='userlisthead'>
              {' '}
              <UserPen />
              Client User {isLoading && <Loader size={25} />}
            </div>
          </div>
        </div>
        <form name='form' onSubmit={handleSubmit}>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3 d-flex justify-content-center align-items-center '>
                <div className='camera-container mb-3'>
                  <label htmlFor='photoupload' className='photouploadicon'>
                    {imageUploading ? (
                      <Loader />
                    ) : (
                      <img
                        className='camera-img'
                        src={
                          clientData?.profile_image
                            ? apiConstants.imgUrlS3 + clientData?.profile_image // Fixed the syntax error by removing 'uri:'
                            : '/img/camera.png'
                        }
                        alt=''
                      />
                    )}
                  </label>
                  <input
                    onChange={handleImageUpload}
                    type='file'
                    id='photoupload'
                    style={{ display: 'none' }}
                    accept='image/*'
                  />
                </div>
              </div>
              <div className='col-md-9 '>
                <div className='row'>
                  <div className='col-md-6'>
                    <div
                      className={
                        'form-group' + (submitted && !clientData.first_name ? ' has-error' : '')
                      }
                    >
                      <input
                        onChange={handleChange}
                        type='text'
                        name='first_name'
                        value={clientData.first_name}
                        className='form-control mb-3'
                      />
                      <span className='floating-label'>First Name</span>{' '}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        onChange={handleChange}
                        type='text'
                        name='last_name'
                        value={clientData.last_name}
                        className='form-control mb-3'
                      />
                      <span className='floating-label'>Last Name (Optional)</span>{' '}
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        onChange={handleChange}
                        className='form-control mb-3'
                        name='email'
                        value={clientData.email}
                        type='email'
                      />
                      <span className='floating-label'>Email (Optional)</span>{' '}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        onChange={handleChange}
                        type='number'
                        name='phone'
                        value={clientData.phone}
                        className='form-control mb-3'
                      />
                      <span className='floating-label'>Phone (Optional)</span>{' '}
                    </div>
                  </div>
                </div>
                <div className='verticaline' />
                <div className='row'>
                  <div className='col-md-6'>
                    <div
                      className={
                        'form-group' + (submitted && !clientData.username ? ' has-error' : '')
                      }
                    >
                      <input
                        onChange={handleChange}
                        className='form-control mb-3'
                        name='username'
                        value={clientData.username}
                        type='text'
                      />
                      <span className='floating-label'>Username</span>{' '}
                    </div>
                  </div>
                  <div className='col-md-6 '>
                    <div
                      className={
                        'form-group position-relative' +
                        (submitted && !clientData.company_id ? ' has-error' : '')
                      }
                    >
                      <AsyncSelect
                        cacheOptions
                        loadOptions={getCompanies}
                        onChange={handleChangeCompany}
                        defaultOptions
                        value={{ label: selectedCompany }}
                        className='async-select-form-control '
                        placeholder='Select Client'
                      />
                      {!selectedCompany && (
                        <span className='floating-label' style={{ fontSize: '11px' }}>
                          Select Client
                        </span>
                      )}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div
                      className={
                        'form-group' + (submitted && !clientData.password ? ' has-error' : '')
                      }
                    >
                      <input
                        onChange={handleChange}
                        className='form-control mb-3'
                        name='password'
                        value={clientData.password}
                        type='password'
                      />
                      <span className='floating-label'>Password</span>{' '}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div
                      className={
                        'form-group' +
                        (submitted && !clientData.confirmpassword ? ' has-error' : '')
                      }
                    >
                      <input
                        onChange={handleChange}
                        className='form-control mb-3'
                        name='confirmpassword'
                        value={clientData.confirmpassword}
                        type='password'
                      />
                      <span className='floating-label'>Confirm Password</span>{' '}
                    </div>
                  </div>
                  <div className='row'>
                    {id == 0 ? (
                      ''
                    ) : (
                      <div className='col-sm-12 mb-3'>
                        <div className='btn-group form-group'>
                          <button
                            className={`btn btn-primary btn-sm ${
                              clientData?.status === 1 ? 'active' : ''
                            }`}
                            onClick={() => handleStatusChange(1)}
                            type='button'
                          >
                            Active
                          </button>
                          <button
                            className={`btn btn-primary btn-sm ${
                              clientData?.status === 0 ? 'active' : ''
                            }`}
                            onClick={() => handleStatusChange(0)}
                            type='button'
                          >
                            Inactive
                          </button>
                        </div>
                      </div>
                    )}
                    <div className='col-sm-12'>
                      <button className='btn btn-primary btn-sm'>
                        {id == 0 ? 'Save' : 'Save changes'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
