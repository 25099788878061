import React from 'react';
import './modal.css'; // Ensure you have some basic styles for the modal

const WarningModal = ({ isOpen, onClose, content, onClickYes }) => {
  if (!isOpen) return null;

  return (
    <div className='modal ' style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}>
      <div className='modal-dialog' style={{ maxWidth: 650 }}>
        <div className='modal-content'>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <div className='modalcontent'>{content}</div>
            <div className='d-flex justify-content-center gap-2 mt-4'>
              <div
                className='warning-buttons'
                onClick={() => {
                  onClose();
                }}
              >
                No
              </div>
              <div
                className='warning-buttons'
                onClick={() => {
                  onClickYes();
                }}
              >
                Yes
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
