import Picker from '@emoji-mart/react';
import { Image, Paperclip, SendHorizontal, Smile } from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingIcon } from '../_components/Loading';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { messageService } from '../_services/message.service';
import { getFileType, miscService, UploadS3UsingFetch } from '../_services/misc.service';
import { useUser } from '../Hooks/useUser';
import { ChatContext } from '../Utils/Contexts';
import { FilePreview } from './FilePreview';

export const ChatSendingBar = ({ handleMessageSend, tab }) => {
  const user = useUser();
  const { candidateData } = useContext(ChatContext);
  const textAreaRef = useRef(null);

  const emojiRef = useRef(null);
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [sending, setIsSending] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'; //+28 for container
    }
  }, [message]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setShowEmojis(false); // Close the emoji picker
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;

    setMessage(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.altKey || e.shiftKey || e.ctrlKey) {
        // If Alt, Shift, or Control is pressed with Enter, insert a new line at the cursor position
        e.preventDefault(); // Prevent the default Enter behavior

        const cursorPosition = e.target.selectionStart;
        const newText = message.slice(0, cursorPosition) + '\n' + message.slice(cursorPosition);
        setMessage(newText);

        // Move the cursor to the position after the new line
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = cursorPosition + 1;
        }, 0);
      } else {
        // Otherwise, send the text
        e.preventDefault(); // Prevent the default Enter behavior
        sendMessage(message, 1);
        // setMessage(''); // Clear the text area
      }
    }
  };

  const sendMessage = async (message, mode) => {
    try {
      setIsSending(true);
      const query = {
        message: message,
        mode: mode,
        receiver_id: user?.role != ROLE.CLIENT_USER ? candidateData?.company_userid : null,
        order_id: candidateData?.order_id,
        order_details_id: candidateData?.orderdetails_id,
        refid: candidateData?.id,
        isNote: tab == 2 ? true : false,
      };

      if (query.message && query.mode && query.order_details_id && query.order_id && query.refid) {
        const data = await messageService.addMessage(query);

        if (data) {
          setMessage('');
          setFile(null);
          handleMessageSend();
        }
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsSending(false);
    }
  };

  const handleSendFile = async (file) => {
    setIsFileUploading(true);

    const ext = file.name.split('.').pop();
    const response = await miscService.createSignedUploadUrl({
      type: 'image',
      mime_type: file.type,
      ext,
    });

    const { signedUrl, filename } = response.data;
    const uploadResponse = await UploadS3UsingFetch(file, signedUrl);
    if (uploadResponse) {
      const mode = getFileType(file?.type) == 'image' ? 2 : 3;
      sendMessage(filename, mode);
      // setUserData({ ...userData, profile_image: filename });
      setIsFileUploading(false);
    } else {
      setIsFileUploading(false);
      toast.error('Upload Failed! Try again!', toastConstant.TOAST_PAYLOAD);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <div className='chatinputcontainer position-relative'>
      {!file && (
        <div className='emojibtn cursor-pointer' ref={emojiRef}>
          <Smile size={18} color='#858796' onClick={() => setShowEmojis(!showEmojis)} />

          {showEmojis && (
            <div style={{ position: 'absolute', bottom: '60px' }}>
              <Picker
                previewPosition={'none'}
                onEmojiSelect={(emoji) => {
                  handleChange({ target: { value: message + emoji.native } });
                }}
                theme={'light'}
              />
            </div>
          )}
        </div>
      )}
      {!file && (
        <textarea
          ref={textAreaRef}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className='chatinput'
          rows='1'
          cols='50'
          placeholder='Write a message... '
        />
      )}
      {file && <FilePreview file={file} key={file} handleRemove={handleRemoveFile} />}
      {message?.length == 0 && !file && tab!==2&&(
        <div
          className='imageupload cursor-pointer'
          onClick={() => document.getElementById('imageinput').click()}
        >
          <Image size={16} color='#858796' />
        </div>
      )}
      {message?.length == 0 && !file && (
        <div
          className='fileupload cursor-pointer'
          onClick={() => document.getElementById('fileInput').click()}
        >
          <Paperclip size={16} color='#858796' />
        </div>
      )}
      <div
        className='sendbtn'
        onClick={() => {
          if (!isFileUploading && !sending)
            if (file) {
              handleSendFile(file);
            } else {
              sendMessage(message, 1);
            }
        }}
      >
        {sending || isFileUploading ? <LoadingIcon size={30} /> : <SendHorizontal />}
      </div>

      <input
        id='fileInput'
        type='file'
        accept='.pdf,.doc,.docx,.txt' // {{ edit_1 }} Restrict file types to PDF, DOC, DOCX, and TXT
        style={{ display: 'none' }}
        onChange={(e) => {
          // Handle file selection
          const file = e.target.files[0];
          if (file) {
            setFile(file);
            // Process the selected file
          }
        }}
      />
      <input
        id='imageinput'
        type='file'
        style={{ display: 'none' }}
        onChange={(e) => {
          // Handle file selection
          const file = e.target.files[0];
          if (file) {
            setFile(file);
          }
        }}
        accept='image/*'
      />
    </div>
  );
};
