export const miscConstants = {
  REQUEST: 1,
  SUCCESS: 2,
  FAILURE: 3,
  CLEAR: 0,
  FILE_REQUEST: 11,
  FILE_SUCCESS: 12,
  FILE_FAILURE: 13,
  FILE_CLEAR: 10,
  IS_MENU_OPEN: 'IS_MENU_OPEN',
  CURRENCY_SIGN: '£',
  CURRENCY: 'GBP',
};
