import {
  ChevronDown,
  ChevronLeft,
  Eye,
  Headset,
  Hourglass,
  Link,
  Phone,
  PhoneIncoming,
  PlaneLanding,
  PlaneTakeoff,
  ShieldCheck,
  Users,
  X,
} from 'lucide-react';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { candidateService } from '../_services/candidate.service';
import { userAbb } from '../_services/misc.service';
import { ChatContainer } from '../Chat/ChatContainer';
import { useUser } from '../Hooks/useUser';
import { ChatContext } from '../Utils/Contexts';
import { ActionHistory } from './ActionHistory';
import './candidate.css';
import { CandidateCV } from './CandidateCV';

const statusOptions = [
  {
    id: 1,
    status: 1,
    label: 'Verified',
    icon: <ShieldCheck size={15} />,
  },
  {
    id: 2,
    status: 5,
    label: 'Please mobilize',
    icon: <PlaneTakeoff size={15} />,
  },
  {
    id: 3,
    status: 4,
    label: 'Online Interview',
    interview: true,
    interview_mode: 1,
    icon: <Headset size={15} />,
  },
  {
    id: 4,
    status: 4,
    label: 'Telephonic Interview',
    interview: true,
    interview_mode: 2,
    icon: <Phone size={15} />,
  },
  {
    id: 5,
    status: 4,
    label: 'Face to face Interview',
    interview: true,
    interview_mode: 3,
    icon: <Users size={15} />,
  },
  {
    id: 6,
    status: 7,
    label: 'Move to Waiting List',
    icon: <Hourglass size={15} />,
  },
  {
    id: 7,
    status: 3,
    label: 'Reject',
    icon: <X size={15} />,
  },
  {
    id: 8,
    status: 6,
    label: 'Mobilized',
    icon: <PlaneLanding size={15} />,
  },
  {
    id: 9,
    status: 8,
    label: 'Interview',
    icon: <PhoneIncoming size={15} />,
  },
];

export const CandidateSingle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStaus] = useState(null);
  const [candidateData, setCandidateData] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState({ status: false, data: null });

  useEffect(() => {
    if (id) {
      getCandidateData(id);
    }
  }, [id]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const optionsContainer = document.querySelector('.optionscontainer');
      const selectedStatus = document.querySelector('.selectedStatus');
      if (
        optionsContainer &&
        !optionsContainer.contains(event.target) &&
        !selectedStatus.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getCandidateData = async (id) => {
    try {
      const query = {
        id: id,
      };

      const res = await candidateService.getCandidateById(query);

      if (res) {
        setCandidateData(res.data);
        setStaus(res?.data.status);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectStatus = async (status, interview_mode) => {
    setStaus(status);
    try {
      const changeQuery = {
        status: status,
        interview_mode: interview_mode ? interview_mode : 0,
        id: candidateData?.id,
      };

      if (user?.role == ROLE.CLIENT_USER) {
        const guest = localStorage.getItem('Guest');
        if (guest) {
          changeQuery.guestuser = guest;
        }
      }

      const response = await candidateService.changeStatus(changeQuery);
      if (response) {
        // getCandidateData(id);
        const historyNew = [...(candidateData?.history || [])];
        candidateData.status = status;
        // candidateData.interview_mode = interview_mode;
        historyNew.push({
          status: status,
          date: new Date().getTime(),
          userid: user?.id,
          interviewmode: interview_mode ?? 0,
          name: user?.first_name ?? '',
        });

        let candidateDataNew = {
          ...candidateData,
          status: status,
          interview_mode: interview_mode,
          history: historyNew,
        }; // Update interview_mode correctly
        setShowOptions(false);
        setCandidateData(candidateDataNew);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside finally data');
    }
  };

  const getLabelByStatus = (status, interview_mode) => {
    const option = statusOptions.find((option) => {
      if (status === 4) {
        return option.interview_mode === interview_mode; // Check interview_mode for status 4
      } else {
        return option.status === status; // Check status for other cases
      }
    });
    return option ? option.label : 'Select'; // Return the label or null if not found
  };

  return (
    <div className='candidate-container'>
      <div className='candidatemainpanel'>
        <div className='candidatesinglecard d-flex justify-content-between pt-4'>
          <div className='profilecard col-md-7'>
            <div className='backicon col-md-1 cursor-pointer' onClick={() => navigate(-1)}>
              <ChevronLeft color='#297ec6' />
            </div>
            <span className='nameabb col-md-2'>{userAbb(candidateData?.name)}</span>
            <div className='col-md-9 ps-5'>
              <h5 className=' candidatename'>{candidateData?.name}</h5>
              <div className='candidatedesc'>{candidateData?.job_description}</div>
              <div
                className='statusselect position-relative d-flex'
                key={candidateData?.interview_mode}
              >
                <div
                  className='selectedStatus'
                  onClick={() => {
                    if (
                      (user?.role == ROLE.USER && (status == 3 || status == 6)) ||
                      (user?.role == ROLE.CLIENT_USER && status == 5)
                    ) {
                      toast.warn(
                        'You Have No access to change Status! Please Contact Admin.',
                        toastConstant.TOAST_PAYLOAD,
                      );
                    } else {
                      setShowOptions(!showOptions);
                    }
                  }}
                >
                  {getLabelByStatus(status, candidateData?.interview_mode)}{' '}
                  <ChevronDown className='chevron-icon' size={15} />
                </div>

                {showOptions && (
                  <div className='optionscontainer'>
                    {statusOptions
                      .filter((option) => {
                        if (user?.role == ROLE.USER && status === 5) {
                          return option.status === 6;
                        }
                        if (user?.role === ROLE.CLIENT_USER) {
                          // Show options where id is 2, 6, or 7 for CLIENT_USER
                          return (
                            option.id === 1 || option.id === 2 || option.id === 7 || option.id == 9
                          );
                        }

                        if (status === 4) {
                          return option.interview_mode !== candidateData?.interview_mode; // Remove equivalent object based on interview_mode
                        }

                        return option.status !== status; // For other statuses, just filter out the current status
                      })
                      .map(({ label, status, interview, interview_mode, icon }) => (
                        <div
                          className='optionitem'
                          key={status}
                          onClick={() => {
                            if (
                              user?.role == ROLE.ADMIN &&
                              (candidateData.status == 3 || candidateData.status === 6)
                            ) {
                              setShowWarningModal({
                                status: true,
                                data: {
                                  status: status,
                                  interview_mode: interview_mode ?? 0,
                                },
                              });
                            } else {
                              if (interview) {
                                handleSelectStatus(4, interview_mode);
                              } else {
                                handleSelectStatus(status);
                              }
                            }
                          }}
                        >
                          {icon}
                          {label}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='otherdetailscard col-md-5'>
            <table className='table'>
              <tbody>
                <tr>
                  <td>
                    <b>Availability</b>
                  </td>
                  <td>:</td>
                  <td>{moment(parseInt(candidateData?.available)).format('MMM-DD-YYYY')}</td>
                </tr>
                {candidateData?.extra_field_value?.map((extradata) =>
                  Object.entries(extradata).map(([key, value]) => (
                    <tr key={key}>
                      <td>
                        <b>{key}</b>
                      </td>
                      <td>:</td>
                      <td>{value}</td>
                    </tr>
                  )),
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div
          className='candidatesinglecard orderdetailscard'
          style={{ border: '0.3px solid #e8e8e8' }}
        >
          <div className='orderdataitem '>
            <b className='orderlabel'>Order</b>
            <div className='ordervalue'>{candidateData?.order_details_index}</div>
          </div>
          <div className='orderdataitem '>
            <b className='orderlabel'>Company</b>
            <div className='ordervalue'>{candidateData?.company_name}</div>
          </div>
          <div className='orderdataitem '>
            <b className='orderlabel'>Position</b>
            <div className='ordervalue'>{candidateData?.job_name}</div>
          </div>
          <div className='orderdataitem '>
            <b className='orderlabel'>Client User</b>
            <div className='ordervalue'>{candidateData?.client_user}</div>
          </div>
        </div>
        <div className='cvcontainer'>
          <div className='cvhead'>
            <CopyToClipboard text={candidateData?.cvurl}>
              <div
                className='copybtn'
                onClick={() => toast.success('Link Copied!', toastConstant.TOAST_PAYLOAD)}
              >
                <Link size={15} />
              </div>
            </CopyToClipboard>

            <a className='openpdfbtn' href={candidateData?.cvurl} target='_blank' rel='noreferrer'>
              <Eye size={15} className='mb-1' /> Full View
            </a>
          </div>
          <CandidateCV cvurl={candidateData?.cvurl} />
        </div>
        {user?.role != ROLE.CLIENT_USER && (
          <div className='historyContainer'>
            <ActionHistory history={candidateData?.history} key={candidateData?.history} />
          </div>
        )}
      </div>
      <div className='chatpanel '>
        <ChatContext.Provider value={{ candidateData }}>
          <ChatContainer data={candidateData} key={candidateData?.id} />
        </ChatContext.Provider>
      </div>
      {isLoading && <div className='loading'>Loading...</div>}
      {/* <WarningModal
        isOpen={showWarningModal?.status}
        content={'Are You Sure Want to Change Candidate Status?'}
        onClickYes={() => {
          handleSelectStatus(
            showWarningModal?.data?.status,
            showWarningModal?.data?.interview_mode,
          ),
            setShowWarningModal({ data: null, status: false });
        }}
        onClose={() => setShowWarningModal({ data: null, status: false })}
      /> */}

      {showWarningModal.status && (
        <div className='modal ' style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}>
          <div className='modal-dialog' style={{ maxWidth: 650 }}>
            <div className='modal-content'>
              <div className='modal-body' style={{ textAlign: 'center' }}>
                <div className='modalcontent'>
                  {'Are You Sure Want to Change Candidate Status?'}
                </div>
                <div className='d-flex justify-content-center gap-2 mt-4'>
                  <div
                    className='warning-buttons'
                    onClick={() => {
                      setShowWarningModal({ data: null, status: false });
                    }}
                  >
                    No
                  </div>
                  <div
                    className='warning-buttons'
                    onClick={() => {
                      handleSelectStatus(
                        showWarningModal?.data?.status,
                        showWarningModal?.data?.interview_mode,
                      ),
                        setShowWarningModal({ data: null, status: false });
                    }}
                  >
                    Yes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
