import React, { useState } from 'react';
import { toast } from 'react-toastify';
import '../_components/modal.css'; // Ensure you have some basic styles for the modal
import { toastConstant } from '../_constants/toast.constants';
import { userService } from '../_services/user.service';

const ChangePasswordModal = ({ isOpen, onClose, Data }) => {
  const [userData, setUserData] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setSubmitted(true);

    const { password, confirmpassword } = userData;
    try {
      if (password && confirmpassword) {
        if (password == confirmpassword) {
          userData.id = Data.id;
          delete userData?.confirmpassword;
          const updated = await userService.changePassword(userData);

          if (updated?.data) {
            onClose();
          }
        } else {
          toast.warn('Passwords Does Not match!', toastConstant.TOAST_PAYLOAD);
        }
      } else {
        toast.warn('Please Complete Form!', toastConstant.TOAST_PAYLOAD);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside finally');
    }
  };

  if (!isOpen) return null;

  return (
    <div className='modal ' style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}>
      <div className='modal-dialog' style={{ maxWidth: 650 }}>
        <div className='modal-content'>
          <div className='modal-header-content'>
            <div>
              <h4 className='card-title' style={{ color: '#b2b2b2' }}>
                Change Password
              </h4>
            </div>
            <div className='close' style={{ marginTop: -6 }}>
              <a onClick={() => onClose()}>&times;</a>
            </div>
          </div>
          <div className='modal-body' style={{ textAlign: 'center' }}>
            <div className={'form-group' + (submitted && !userData.password ? ' has-error' : '')}>
              <input
                onChange={handleChange}
                className='form-control mb-3'
                name='password'
                value={userData.password}
                type='password'
              />
              <span className='floating-label'>Password</span>{' '}
            </div>
            <div
              className={
                'form-group' + (submitted && !userData.confirmpassword ? ' has-error' : '')
              }
            >
              <input
                onChange={handleChange}
                className='form-control mb-3'
                name='confirmpassword'
                value={userData.confirmpassword}
                type='password'
              />
              <span className='floating-label'>Confirm Password</span>{' '}
            </div>
            {/* <div className='d-flex justify-content-center gap-2 mt-4'> */}
            <div
              className='changepasswordbutton'
              onClick={() => {
                handleSubmit();
              }}
            >
              Change Password
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
