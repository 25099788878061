import { Plus, UserRoundPen } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Pagination } from '../_components';
import { Loader } from '../_components/Loader';
import { NoData } from '../_components/NoData';
import WarningModal from '../_components/WarningModal';
import { apiConstants } from '../_constants';
import { toastConstant } from '../_constants/toast.constants';
import { userService } from '../_services/user.service';
import './user.css';

export const UserList = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    role: 2,
  });

  useEffect(() => {
    getAllUserList(1);
  }, []);

  const getAllUserList = async (pageNo, searchKey) => {
    if (pageNo) {
      query.pageVo.pageNo = pageNo;
    }
    if (searchKey) {
      query.searchKey = searchKey;
    } else {
      query.searchKey = '';
    }
    setIsLoading(true);
    setQuery(query);
    try {
      const response = await userService.usersList(query);

      if (response.data.rows) {
        setPaginationData({
          page: response.data.page,
          pages: response.data.pages,
          total: response.data.total,
        });

        setUserData(response.data.rows);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const resetPassword = async () => {
    try {
      const resetQuery = {
        id: selectedUser?.id,
      };
      const response = await userService.resetPassword(resetQuery);
      if (response.data.length) {
        setShowWarningModal(false);
        setSelectedUser({});
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('comleted reset');
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    getAllUserList(1, value);
  };

  const renderUserItem = () => {
    return userData.map((user) => {
      return (
        <tr key={user?.id}>
          <td>
            <img
              className='userimgicon'
              src={
                user?.profile_image
                  ? apiConstants.imgUrlS3 + user?.profile_image
                  : '/img/user_dummy.png'
              }
            />
          </td>
          <td>{user?.first_name}</td>
          <td>{user?.phone}</td>
          <td>{user?.username}</td>
          <td>
            {/* <span className='statusidicator'>{user?.status == 1 ? 'Active' : 'Inactive'}</span> */}
            <span className='activeicon'>{user?.status == 1 ? 'Active' : 'Inactive'}</span>
          </td>
          <td>
            <UserRoundPen
              color='grey'
              size={20}
              className='cursor-pointer'
              onClick={() => navigate('/usersadd/' + user?.id)}
            />
          </td>
          <td>
            {' '}
            <span
              className='resettxt cursor-pointer'
              onClick={() => {
                setShowWarningModal(true);
                setSelectedUser(user);
              }}
            >
              Reset Password
            </span>
          </td>
        </tr>
      );
    });
  };
  return (
    <div>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className='userlisthead'>
              <img src='/img/usercardicon.png' className='userheadicon' />
              Madre Team
            </div>
            <div className='searchbarcontainer'>
              <input
                type='text'
                className='searchbar'
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder='Search User'
              />
            </div>
            <div className='addbtncontainer'>
              <div
                className='adduserbtn'
                onClick={() => {
                  navigate('/usersadd/0');
                }}
              >
                <Plus size={15} strokeWidth={3} />
                Member
              </div>
            </div>
          </div>
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th>Photo</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Username</th>
                <th>status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {userData.length == 0 && !isLoading && <NoData />}
          {!!isLoading && <Loader />}

          <Pagination
            OnClickPage={(pageNo) => {
              getAllUserList(pageNo);
            }}
            demoData={paginationData}
          />
        </div>
      </div>
      <WarningModal
        isOpen={showWarningModal}
        content={'Do you Want to reset password of this user?'}
        onClose={() => {
          setShowWarningModal(false);
        }}
        onClickYes={resetPassword}
      />
    </div>
  );
};
