import { Download } from 'lucide-react';
import React, { useState } from 'react';
import '../Candidate/candidate.css';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

export const DownloadCv = ({ cvurl }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFilesAsZip = async () => {
    try {
      setIsLoading(true);
      const urlParams = cvurl.map((url) => `urls=${encodeURIComponent(url)}`).join('&');
      const response = await fetch(`${apiConstants.apiUrl}/downloadzip?${urlParams}`, {
        method: 'GET',
        headers: authHeader(),
      });

      if (!response.ok) throw new Error('Failed to download zip file');

      const blob = await response.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'files.zip';
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading zip:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div onClick={() => downloadFilesAsZip(cvurl)} className='downloadbtn'>
      {isLoading ? (
        'Downloading...'
      ) : (
        <>
          <Download size={15} /> CV
        </>
      )}
    </div>
  );
};
