import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUser } from '../Hooks/useUser';
import { ChatContext } from '../Utils/Contexts';
import { ROLE } from '../_constants/role.constants';
import { toastConstant } from '../_constants/toast.constants';
import { messageService } from '../_services/message.service';
import { ChatList } from './ChatList';
import { ChatSendingBar } from './ChatSendingBar';
import { Notes } from './Notes';
import './chat.css';

export const ChatContainer = () => {
  const user = useUser();
  const { candidateData } = useContext(ChatContext);

  const [chatList, setChatList] = useState([]);
  const [tab, setTab] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 100,
    },
  });

  useEffect(() => {
    if (candidateData.order_id && candidateData?.orderdetails_id && candidateData?.id) {
      getAllChat(1);
    }
  }, [candidateData]);

  const getAllChat = async (pageNo, tab) => {
    setIsLoading(true);
    if (pageNo) {
      query.pageVo.pageNo = pageNo;
    }
    if (tab && tab == 2) {
      query.isNote = true;
    } else {
      query.isNote = false;
    }
    if (candidateData.order_id && candidateData?.orderdetails_id && candidateData?.id) {
      query.order_id = candidateData?.order_id;
      query.order_details_id = candidateData?.orderdetails_id;
      query.refid = candidateData?.id;
    } else {
      toast.error('something went wrong', toastConstant.TOAST_PAYLOAD);
    }
    setQuery(query);

    try {
      const data = await messageService.messageList(query);

      if (data) {
        setChatList(data.data.rows);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='chatcontainer'>
      {user?.role != ROLE.CLIENT_USER && (
        <div className='d-flex gap-3 chattabcontainer'>
          <div
            className={'chattab' + (tab == 1 ? ' selectedchattab' : '')}
            onClick={() => {
              getAllChat(1, 1), setTab(1);
            }}
          >
            Chats
          </div>
          <div
            className={'chattab' + (tab == 2 ? ' selectedchattab' : '')}
            onClick={() => {
              getAllChat(1, 2), setTab(2);
            }}
          >
            Comments
          </div>
        </div>
      )}
      {tab == 1 && <ChatList chatList={chatList} isLoading={isLoading} />}
      {tab == 2 && <Notes notesList={chatList} isLoading={isLoading} />}
      <ChatSendingBar handleMessageSend={() => getAllChat(1, tab)} tab={tab} />
    </div>
  );
};
