import { File } from 'lucide-react';
import moment from 'moment';
import React from 'react';

export const ChatMessage = ({ chat, userId }) => {
  const urlPattern = /https?:\/\/[^\s]+/;

  const isSender = userId == chat?.sender_id;

  return (
    <div className='messageitem '>
      <div
        className={'position-relative ' + (isSender ? 'sendermessagebox' : 'receivermessagebox')}
      >
        {chat?.mode == 1 && (
          <span>
            {chat?.message.split(/(https?:\/\/[^\s]+)/g).map((part, index) =>
              urlPattern.test(part) ? (
                <a
                  key={index}
                  href={part}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='linkmsg'
                >
                  {part}
                </a>
              ) : (
                part
              ),
            )}
          </span>
        )}
        {chat.mode == 2 && (
          <img
            src={chat?.fileurl}
            className='chatimage cursor-pointer'
            onClick={() => {
              window.open(chat?.fileurl, '_blank');
            }}
          />
        )}
        {chat.mode == 3 && (
          <div
            className='shared-file cursor-pointer'
            onClick={() => {
              window.open(chat?.fileurl, '_blank');
            }}
          >
            <File color='#000' />
            <span className='file-name'>Document</span>
          </div>
        )}
        <span className={isSender ? 'datelabelright' : 'datelabelleft'}>
          {moment(chat?.createdAt).format('h:mm A | MMM D')}
        </span>
      </div>
    </div>
  );
};
