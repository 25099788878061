import React from 'react';
export const LoadingIcon = ({ color = '#BCCBFF', className = '', size = 210 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      viewBox='0 0 200 200'
      width={size}
      height={size}
    >
      <circle fill={color} stroke={color} strokeWidth='15' r='15' cx='35' cy='100'>
        <animate
          attributeName='cx'
          calcMode='spline'
          dur='2s'
          values='35;165;165;35;35'
          keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1'
          repeatCount='indefinite'
          begin='0s'
        />
      </circle>
      <circle fill={color} stroke={color} strokeWidth='15' opacity='.8' r='15' cx='35' cy='100'>
        <animate
          attributeName='cx'
          calcMode='spline'
          dur='2s'
          values='35;165;165;35;35'
          keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1'
          repeatCount='indefinite'
          begin='0.05s'
        />
      </circle>
      <circle fill={color} stroke={color} strokeWidth='15' opacity='.6' r='15' cx='35' cy='100'>
        <animate
          attributeName='cx'
          calcMode='spline'
          dur='2s'
          values='35;165;165;35;35'
          keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1'
          repeatCount='indefinite'
          begin='.1s'
        />
      </circle>
      <circle fill={color} stroke={color} strokeWidth='15' opacity='.4' r='15' cx='35' cy='100'>
        <animate
          attributeName='cx'
          calcMode='spline'
          dur='2s'
          values='35;165;165;35;35'
          keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1'
          repeatCount='indefinite'
          begin='.15s'
        />
      </circle>
      <circle fill={color} stroke={color} strokeWidth='15' opacity='.2' r='15' cx='35' cy='100'>
        <animate
          attributeName='cx'
          calcMode='spline'
          dur='2s'
          values='35;165;165;35;35'
          keySplines='0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1'
          repeatCount='indefinite'
          begin='.2s'
        />
      </circle>
    </svg>
  );
};
