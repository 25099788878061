import { FileUser } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { Pagination } from '../_components';
import { Loader } from '../_components/Loader';
import { NoData } from '../_components/NoData';
import { apiConstants } from '../_constants';
import { toastConstant } from '../_constants/toast.constants';
import { authHeader } from '../_helpers';
import { candidateService } from '../_services/candidate.service';
import { manipulateOrderId } from '../_services/misc.service';
import './candidate.css';

const options = [
  { label: 'All', status: -1 },
  { label: 'New', status: 1 },
  { label: 'Interview', status: 4 },
  { label: 'Please Mobilize', status: 5 },
  { label: 'Rejected', status: 3 },
  { label: 'Mobilized', status: 6 },
  { label: 'Waiting List', status: 7 },
];
export const CandidateList = () => {
  const navigate = useNavigate();
  const [candidateData, setCandidateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllCandidateList(1);
  }, []);

  const getAllCandidateList = async (pageNo, filter, key) => {
    if (pageNo) {
      query.pageVo.pageNo = pageNo;
    }

    if (filter && key) {
      query[filter] = key;
    } else {
      query.searchKey = '';
    }

    if (filter == 'status' && key == -1) {
      delete query.status;
    }

    setIsLoading(true);
    setQuery(query);
    try {
      const response = await candidateService.CandidateList(query);

      if (response.data.rows) {
        setPaginationData({
          page: response.data.page,
          pages: response.data.pages,
          total: response.data.total,
        });

        setCandidateData(response.data.rows);
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    getAllCandidateList(1, 'searchKey', value);
  };

  const renderUserItem = () => {
    return candidateData?.map((candidate) => {
      const orderId = manipulateOrderId(candidate?.orderref_id, 3, candidate?.order_details_index);

      return (
        <tr
          key={candidate?.id}
          className='cursor-pointer'
          onClick={() => navigate('/candidatesingle/' + candidate?.id)}
        >
          <td>{candidate?.name}</td>
          <td>{candidate?.phone}</td>
          <td>{candidate?.email}</td>
          <td>{candidate?.job_name}</td>
          <td>{candidate?.status}</td>
          <td>{orderId}</td>
          <td>{candidate?.added_by}</td>
          <td>
            <div className='d-flex' style={{ flexDirection: 'column' }}>
              <b className='ng-binding'>{moment(candidate?.createdAt).format('MMM D, YYYY')}</b>
              <span className='ng-binding'>{moment(candidate?.createdAt).format(' h:mm A')}</span>
            </div>
          </td>
          <td>{candidate?.client_user}</td>
          <td>{candidate?.company_name}</td>
        </tr>
      );
    });
  };

  const getJobRoles = (searchKey) =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/jobrolelist', {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          pageVo: {
            pageNo: 1,
            noOfItems: 100,
          },
          searchKey: searchKey,
          include: ['id', 'name'],
        }),
      })
        .then((response) =>
          response.json().then((data) => {
            resolve(
              data.data.rows.map(({ id, name }) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            );
          }),
        )
        .catch((err) => {
          reject(err);
        });
    });

  const handleChangeJobRole = (selectedoption) => {
    setQuery((prev) => ({
      ...prev,
      jobtitleid: selectedoption?.value,
    }));
    getAllCandidateList(1, 'jobtitleid', selectedoption?.value);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: 30, // Set minimum height
      height: 30, // Set height directly
      padding: '0 0px', // Adjust padding for alignment
      display: 'flex', // Ensure flex display for alignment
      alignItems: 'center', // Center items vertically
    }),

    container: (base) => ({
      ...base,
      height: 30, // Set height directly
    }),
    input: (base) => ({
      ...base,
      height: 30,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: -8,
    }),
    ValueContainer: (base) => ({
      ...base,
      height: 30, // Ensure height is consistent
      display: 'flex', // Ensure flex display for alignment
      alignItems: 'center', // Center items vertically
    }),
    placeholder: (styles) => ({ ...styles }),
    // DropdownIndicator: (styles) => ({
    //   ...styles,
    // }),
    indicatorSeparator: () => ({
      width: 0,
    }),
    indicatorsContainer: (styles) => ({
      marginTop: 0,
      ...styles,
      height: 30,
    }),
  };

  const handleStatsFilter = (e) => {
    const { value } = e.target;
    getAllCandidateList(1, 'status', value);
  };
  return (
    <div className='candidatelist'>
      <div className=' userlistcard'>
        <div className='card-header'>
          <div className='d-flex flex-wrap'>
            <div className='userlisthead'>
              <FileUser />
            </div>
            <div className='searchbarcontainer maxwidth-350'>
              <input
                type='text'
                className='searchbar'
                onChange={(e) => {
                  handleSearch(e);
                }}
                placeholder='Search Candidate'
              />
            </div>
            <div className='statuscontainer'>
              {/* <div className='statuslbl'>Status :</div> */}
              <div className='position-relative'>
                <select className='form-control height-30' onChange={handleStatsFilter}>
                  {options?.map(({ label, status }) => (
                    <option value={status} key={status}>
                      {label}
                    </option>
                  ))}
                </select>
                <span className='floating-status'>Status</span>{' '}
              </div>
            </div>
            <div className='searchbarcontainer maxwidth-250'>
              <AsyncSelect
                cacheOptions
                loadOptions={getJobRoles}
                onChange={(selectedoption) => handleChangeJobRole(selectedoption)}
                defaultOptions
                styles={customStyles}
                className='async-select-form-control mb-0 width-200 height-30'
              />
            </div>
          </div>
        </div>
        <div className='card-body table-responsive'>
          <table className='table table-inbox table-hover'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Position</th>
                <th>status</th>
                <th>Order #</th>
                <th>Added by</th>
                <th>Created Date</th>
                <th>Client User</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>{renderUserItem()}</tbody>
          </table>
          {candidateData?.length == 0 && !isLoading && <NoData />}
          {candidateData?.length == 0 && isLoading && <Loader />}
          <Pagination
            OnClickPage={(pageNo) => {
              getAllCandidateList(pageNo);
            }}
            demoData={paginationData}
          />
        </div>
      </div>
    </div>
  );
};
