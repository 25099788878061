/* eslint-disable no-unused-vars */
import { FolderUp } from 'lucide-react';
import React from 'react';
import { ROLE } from '../_constants/role.constants';
import { STATUS_CONSTANT } from '../_constants/status.constants';
import { useUser } from '../Hooks/useUser';

const ExportToCsv = ({ data }) => {
  const user = useUser();

  // Function to remove unwanted fields and flatten extrafield array into key-value pairs
  const removeUnwantedFieldsAndFlattenExtraField = (item) => {
    const {
      jobtitleid,
      cvurl,
      history,
      order_id,
      user_id,
      interview_mode,
      status_userid,
      company_userid,
      agency_id,
      createdAt,
      updatedAt,
      orderref_id,
      experience,
      orderdetails_id,
      available,
      company_id,
      extra_field_value,
      order_details_index,
      status,
      ...rest
    } = item;
    const statusLabel = STATUS_CONSTANT[status] || 'Unknown';

    if (user?.role === ROLE.CLIENT_USER) {
      delete rest.phone; // Remove phone number
      delete rest.email; // Remove email
    }
    // Convert extrafield array into an object of key-value pairs
    if (extra_field_value.length) {
      const extraFields = extra_field_value.reduce((acc, field) => {
        // Use the first key of the field object as the key in the accumulator
        const fieldKey = Object.keys(field)[0];
        const fieldValue = field[fieldKey];

        if (fieldKey && fieldValue) {
          acc[fieldKey] = fieldValue;
        }
        return acc;
      }, {});
      return { ...rest, status: statusLabel, ...extraFields };
    }
    return { ...rest, status: statusLabel };

    // Combine the rest of the fields and the extraFields
  };

  // Process data by removing unwanted fields and including extra fields
  const processedData = data.map(removeUnwantedFieldsAndFlattenExtraField);

  // const convertArrayToCSV = (array) => {
  //   const header = Object.keys(array[0]).join(','); // Get the headers
  //   const rows = array.map((item) => Object.values(item).join(',')); // Convert each object to a comma-separated string
  //   return [header, ...rows].join('\n'); // Combine headers and rows with line breaks
  // };

  const convertArrayToCSV = (array) => {
    const header = Object.keys(array[0]).join(','); // Get the headers
    const rows = array.map((item) =>
      Object.values(item)
        .map((value) =>
          // Wrap values in quotes if they contain commas
          typeof value === 'string' && value.includes(',') ? `"${value}"` : value,
        )
        .join(','),
    ); // Convert each object to a comma-separated string
    return [header, ...rows].join('\n'); // Combine headers and rows with line breaks
  };

  const exportCSV = () => {
    const csv = convertArrayToCSV(processedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'candidateList.csv'); // Filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div onClick={exportCSV} className='exportbtn'>
      <FolderUp size={15} /> {'  '} CSV
    </div>
  );
};

export default ExportToCsv;
