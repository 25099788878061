import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const orderService = {
    logout,
    getOrderDetailsListByorder,
    addOrder,
    orderList,
    getOrderDetailsById,
    getOrderById

};



function getOrderDetailsListByorder(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getorderdetailsbyorder', requestOptions).then(handleResponse);
}
function getOrderDetailsById(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getorderdetailsbyid', requestOptions).then(handleResponse);
}
function getOrderById(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getorderbyid', requestOptions).then(handleResponse);
}
function addOrder(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/addorder', requestOptions).then(handleResponse);
}
function orderList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/orderdetailslist', requestOptions).then(handleResponse);
}



