import React from 'react';
import { DashboardNotifications } from './DashboardNotifications';
import { DashboardOrderList } from './DashboardOrderList';
import { DashboradCounts } from './DashboradCounts';
import './dashboard.css';

export default function Home() {
  return (
    <div className='dashboard_container'>
      <div className='maincontainer'>
        <div className='countcontainer'>
          <DashboradCounts />
        </div>
        <div className='orderlistsection'>
          <DashboardOrderList />
        </div>
      </div>
      <div className='subcontainer'>
        <DashboardNotifications />
      </div>
    </div>
  );
}
