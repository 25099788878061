import { Bounce } from "react-toastify";

export const toastConstant = {
    TOAST_PAYLOAD: {
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
    }
}