import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function ProtectedRoute({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('user') === null) {
      navigate('/login', { replace: true });
    }
  }, [navigate]);

  return children;
}
