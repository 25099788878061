import { Calendar } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastConstant } from '../_constants/toast.constants';
import { orderService } from '../_services/order.service';
import { AppliedCandidateList } from './AppliedCandidateList';
import { RelatedOrders } from './RelatedOrders';

export const OrderSingle = () => {
  const { id, order_id } = useParams();
  const [clientData, setClientData] = useState({});
  const [orderDetails, setOrderDetails] = useState({
    order_details_index: 1,
    user: '',
    client: '',
    project: '',
  });

  const [orderList, SetOrderList] = useState([]);

  useEffect(() => {
    if (order_id) {
      getOrderDetailsListByOrder(order_id);
    }
  }, [order_id]);

  const getOrderDetailsListByOrder = async (order_id) => {
    try {
      const orderQuery = {
        order_id: order_id,
      };

      const res = await orderService.getOrderDetailsListByorder(orderQuery);

      if (res) {
        SetOrderList(res?.data?.data);
        if (res?.data?.client_user) {
          setClientData(res?.data?.client_user);
        }

        const matchedOrder = res.data.data.find((order) => parseInt(order.id) === parseInt(id)); // Ensure id is parsed to an integer
        setOrderDetails(matchedOrder || {}); // Set to an empty object if matchedOrder is undefined
      }
    } catch (err) {
      toast.error(err.message, toastConstant.TOAST_PAYLOAD);
    } finally {
      console.log('inside finally block');
    }
  };
  return (
    <div className='ordersingleviewContainer'>
      <div className='sidepanel '>
        <div className='orderdetails'>
          <div className='descitem'>
            <p className='desclabel'>Order</p>
            <b className='descvalue'>#{orderDetails?.order_details_index}</b>
          </div>
          <div className='descitem'>
            <p className='desclabel'>Client User</p>
            <b className='descvalue'>{orderDetails?.client_user}</b>
          </div>
          <div className='descitem'>
            <p className='desclabel'>Client</p>
            <b className='descvalue'>{orderDetails?.company}</b>
          </div>
          <div className='descitem'>
            <p className='desclabel'>Project</p>
            <b className='descvalue'>{orderDetails?.project_name}</b>
          </div>
          <div className='descitem'>
            <p className='desclabel'>Date</p>
            <b className='descvalue'>
              <Calendar size={15} className='me-1 mb-1' />
              {moment(orderDetails?.createdAt).format('DD-MM-YYYY')}
            </b>
          </div>
        </div>
        <RelatedOrders
          orderList={orderList}
          onClick={setOrderDetails}
          selected={orderDetails?.id}
          key={orderList}
        />
      </div>
      <div className='mainpanel'>
        <AppliedCandidateList
          orderData={{
            order_id: orderDetails?.order_id,
            orderDetails_id: orderDetails?.id,
            extra_field: orderDetails?.extra_field,
          }}
          key={orderDetails?.id}
          clientData={clientData}
        />
      </div>
    </div>
  );
};
