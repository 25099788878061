import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const candidateService = {
    logout,
    CandidateList,
    addCandidate,
    getCandidateById,
    candidateBulkAdd,
    deleteCandidate,
    changeStatus

};




function getCandidateById(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getcandidatebyid', requestOptions).then(handleResponse);
}
function addCandidate(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/addcandidate', requestOptions).then(handleResponse);
}
function candidateBulkAdd(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/candidatebulkadd', requestOptions).then(handleResponse);
}
function CandidateList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/candidatelist', requestOptions).then(handleResponse);
}
function changeStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/changecandidatestatus', requestOptions).then(handleResponse);
}
function deleteCandidate(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/deletecandidate', requestOptions).then(handleResponse);
}



