/*
      ####################################################
       Copy this code to _constants/reducer.constants.js
      ###################################################
 
     DEMO_SUCCESS:'DEMO_SUCCESS',
     DEMO_REQUEST:'DEMO_REQUEST',
     DEMO_FAILURE:'DEMO_FAILURE', 
  

    */

export function demo(
  state = { requestStatus: 0, demoData: {}, data: {} },
  action,
) {
  switch (action.type) {
    case 'DEMO_SUCCESS':
      return {
        ...state,
        requestStatus: 'DEMO_SUCCESS',
        requestDemoStatus: 'DEMO_SUCCESS',
        demoData: action.data.data,
        data: action.data,
      };

    case 'DEMO_FAILURE':
      return {
        ...state,
        requestStatus: 'DEMO_FAILURE',
        requestDemoStatus: 'DEMO_FAILURE',
        demoData: {},
        data: action.data,
      };

    case 'DEMO_REQUEST':
      return {
        ...state,
        requestStatus: 'DEMO_REQUEST',
        requestDemoStatus: 'DEMO_REQUEST',
        data: action.data,
      };

    default:
      return state;
  }
}
