import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const jobService = {
    logout,
    getJobyRoleById,
    addJobRole,
    jobRoleList

};



function getJobyRoleById(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getjobrolebyid', requestOptions).then(handleResponse);
}
function addJobRole(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/addjobrole', requestOptions).then(handleResponse);
}
function jobRoleList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/jobrolelist', requestOptions).then(handleResponse);
}


