import { apiConstants } from '../_constants';
import { authHeader, handleResponse, logout } from '../_helpers';

export const companyService = {
    logout,
    getCompanyById,
    addCompany,
    companyList

};



function getCompanyById(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/getCompanybyid', requestOptions).then(handleResponse);
}
function addCompany(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/addcompany', requestOptions).then(handleResponse);
}
function companyList(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(apiConstants.apiUrl + '/companylist', requestOptions).then(handleResponse);
}


