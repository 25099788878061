import React from 'react';
import { LoadingIcon } from './Loading';

export const Loader = ({ size }) => {
  return (
    <div className='d-flex justify-content-center align-items-center'>
      <LoadingIcon size={size ?? 80} className='loading' />
    </div>
  );
};
